<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Número',
          field: 'numero',
        },
        {
          type: 'text',
          label: 'Cliente',
          field: 'cliente',
        },
        {
          type: 'date',
          label: 'Data de validade',
          field: 'dataValidade',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: [
            'Todos',
            'Editando',
            'Enviado ao cliente',
            'Autorizado',
            'Cancelado',
            'Não autorizado pelo cliente',
            'Visualizado pelo cliente',
            'Importado para NFe',
          ],
        },
      ]"
    />
    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      class="mb-5"
      style="position: absolute; top: 0; left: 50px"
      v-if="actionsStore.selectedRows.length > 0"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item
        v-if="actionsStore.selectedRows[0]?.status != 8"
        v-b-modal.atualizarOrcamento
      >
        Atualizar status
      </b-dropdown-item>
      <b-dropdown-item
        v-b-modal.duplicarOrcamento
        :disabled="actionsStore.selectedRows.length > 1"
      >
        Duplicar Orçamento
      </b-dropdown-item>
      <b-dropdown-item
        v-b-modal.custoOrcamento
        :disabled="actionsStore.selectedRows.length > 1"
      >
        Custo do Orçamento
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      id="custoOrcamento"
      title="Deseja visualizar o custo do Orçamento selecionado?"
      centered
      size="xs"
    >
      <label
        >Verificar o custo do Orçamento Número:
        {{ actionsStore.selectedRows[0]?.numero }}</label
      >
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="custoOrcamento"
            ref="doubleOrcamento"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="duplicarOrcamento"
      title="Deseja duplicar o Orçamento selecionado?"
      centered
      size="xs"
    >
      <label
        >Orçamento a ser duplicado
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="duplicar"
            ref="doubleOrcamento"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="atualizarOrcamento"
      title="Deseja atualizar o status do(s) Orçamento(s) selecionado(s)?"
      centered
      size="xs"
    >
      <label
        >Orçamento(s) para mudar o status
        <p>
          Número(s):
          {{ actionsStore.selectedRows.map((os) => os.numero).join(",") }}
        </p>
      </label>
      <b-form-group label="Selecione o status" label-for="status">
        <VueSelect
          ref="buscarStatus"
          :filterable="false"
          :options="orcamentoStatus"
          v-model="statusSelected"
        >
          <template slot="no-options"> Informe o status </template>
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.descricao }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.descricao }}
            </div>
          </template>
        </VueSelect>
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="atualizarStatus"
            ref="updateOrcamento"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="Orçamentos"
      acaoBtn="/#/orcamento-novo"
      textoBtn="Novo Orçamento"
      api-url="/orcamento/lista"
      :fields="fields"
      classFilterColumn="numero"
      ref="orcamentoList"
      :perPage="30"
      :multiselect="true"
    >
      <template slot="customRow" scope="props">
        <span slot="numero" class="pl-0 pt-8" v-if="props.field == 'numero'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.numero }}
            </div>
          </div>
        </span>
        <span slot="cliente" class="pl-0 pt-8" v-if="props.field == 'cliente'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.cliente }}
            </div>
          </div>
        </span>
        <span
          slot="valorTotal"
          v-if="props.field == 'valorTotal'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          R$
          {{ numberToReal(props.rowData.valorTotal, 2) }}
        </span>
        <span
          slot="status"
          v-if="props.field == 'status'"
          :class="statusClass(props.rowData.status)"
        >
          {{ statusLabel(props.rowData.status) }}
        </span>
        <span
          slot="dataValidade"
          class="pl-0 pt-8"
          v-if="props.field == 'dataValidade'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.dataValidade | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>
        <span
          slot="actions"
          v-if="props.field == 'actions'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          <div class="action-buttons">
            <button
              @click="editarOrcamento(props.rowData)"
              v-if="
                props.rowData.status !== 4 &&
                props.rowData.status !== 3 &&
                props.rowData.status !== 8
              "
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
              v-b-tooltip.hover.bottom="'Editar orçamento'"
            >
              <PencilIcon :size="20" />
            </button>
            <button
              @click="doCopy(props.rowData, props.rowData.id_orcamento)"
              v-if="props.rowData.status !== 4"
              class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
              v-b-tooltip.hover.bottom="'Copiar Link'"
              placement="bottom"
            >
              <Link :size="16" />
            </button>
            <button
              v-if="checkImpressao(props.rowData)"
              @click="imprimirPDF(props.rowData)"
              v-b-tooltip.hover.bottom="'Gerar Pdf'"
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
            >
              <PdfIcon :size="22" />
            </button>
            <button
              @click="shareToWhatsapp(props.rowData)"
              v-b-tooltip.hover.bottom="'Encaminhar via Whatsapp'"
              class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
            >
              <Whatsapp :size="20" />
            </button>
            <button
              @click="excluir(props.rowData)"
              v-if="props.rowData.status === 1 && !store.pafNFCe"
              class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
              v-b-tooltip.hover.bottom="'Excluir'"
            >
              <TrashCanIcon :size="20" />
            </button>
            <button
              @click="openTimeline(props.rowData)"
              class="btn btn-sm btn-warning btn-hover-danger btn-icon mr-2"
              v-b-tooltip.hover.bottom="'Ver timeline'"
            >
              <TimelineClockOutline :size="20" />
            </button>
          </div>
        </span>
      </template>
    </TableApp>
    <SendToWppModal
      :openModal="wppOpenModal"
      :data="dataToWhatsapp"
      @handleOpenModal="(openModal) => (wppOpenModal = openModal)"
      module="orcamento"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table2";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import PdfIcon from "vue-material-design-icons/FilePdfBox.vue";
import TimelineClockOutline from "vue-material-design-icons/TimelineClockOutline.vue";
import Link from "vue-material-design-icons/LinkPlus.vue";
import FilterFields from "@/components/FilterFields";
import SendToWppModal from "@/components/SendToWppModal";
import { actionsStore } from "@/components/Table/actionsStore";
import Menu from "vue-material-design-icons/Menu";
import VueSelect from "vue-select";
import { store } from "../storeOrcamento";

export default {
  name: "orcamento",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    PencilIcon,
    TrashCanIcon,
    PdfIcon,
    TimelineClockOutline,
    Link,
    Whatsapp,
    FilterFields,
    SendToWppModal,
    Menu,
    VueSelect,
  },
  data() {
    return {
      store,
      actionsStore,
      components: {
        flatPickr,
      },
      dataToWhatsapp: null,
      wppOpenModal: false,
      fields: [
        {
          label: "Número",
          field: "numero",
          slot: true,
          sortable: false,
          width: "15%",
        },
        {
          label: "Cliente",
          field: "cliente",
          type: "text",
          slot: true,
          sortable: false,
          width: "35%",
        },
        {
          label: "Valor Total",
          field: "valorTotal",
          sortable: true,
          slot: true,
          width: "10%",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
          width: "15%",
        },

        {
          label: "Valiadde",
          field: "dataValidade",
          sortable: false,
          slot: true,
          width: "10%",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
        },
      ],
      numeroOrcamento: null,
      cliente: null,
      correcaoText: null,
      pdfCorrecaoUrl: null,
      xmlCorrecaoUrl: null,
      nfeId: null,
      form: {},
      orcamentoStatus: [],
      statusSelected: null,
    };
  },
  methods: {
    checkImpressao(pedido) {
      if (!this.store.pafNFCe) {
        return true;
      }

      if (pedido.status == 8) {
        return false;
      }

      return true;
    },
    doCopy(data, id_orcamento) {
      swal({
        title: "Deseja enviar o link para o cliente?",
        text: "Ao confirmar será copiado para a area de transferencia o link",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http.post("/orcamento/update-timeline", {
          id_orcamento,
        });
        swal.close();
        swal.stopLoading();
        this.$copyText(data.link);
        this.$toast.success("Link copiado com sucesso!");
      });
    },
    shareToWhatsapp(data) {
      this.wppOpenModal = true;
      this.dataToWhatsapp = data;
    },

    openTimeline(data) {
      this.$router.push({
        name: "orcamentoTimeline",
        params: {
          id_orcamento: data.id_orcamento,
        },
      });
    },

    imprimirPDF(data) {
      swal({
        title: "Deseja gerar o pdf do Orçamento?",
        text: "Aguarde, pois será gerado o pdf automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/orcamento/print", {
            id_orcamento: data.id_orcamento,
          })
          .then((result) => {
            if (result["data"]["url_orcamento"]) {
              window.open(result["data"]["url_orcamento"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("PDF gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    async editarOrcamento(data) {
      swal({
        title: "Deseja editar o orçamento?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/orcamento/find", {
            id_orcamento: data.id_orcamento,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "orcamentoNovo",
              params: { orcamentoData: response.data },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint do Orçamento");
            swal.close();
          });
      });
    },
    statusClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-warning";
        case 2:
          return "label label-lg label-inline label-light-primary";
        case 3:
          return "label label-lg label-inline label-light-success";
        case 4:
          return "label label-lg label-inline label-light-danger";
        case 5:
          return "label label-lg label-inline label-light-success";
        case 6:
          return "label label-lg label-inline label-light-danger";
        case 7:
          return "label label-lg label-inline label-light-primary";
        case 8:
          return "label label-lg label-inline label-light-primary";
        case 9:
          return "label label-lg label-inline label-light-warning";
        case 10:
          return "label label-lg label-inline label-light-info";
        case null:
          return "label label-lg label-inline label-light-primary";
        default:
          return "label label-lg label-inline label-light-warning";
      }
    },
    statusLabel(status) {
      switch (status) {
        case 1:
          return "Editando";
        case 2:
          return "Enviado ao cliente";
        case 3:
          return "Autorizado";
        case 4:
          return "Cancelado";
        case 5:
          return "Finalizado";
        case 6:
          return "Não autorizado pelo cliente";
        case 7:
          return "Visualizado pelo cliente";
        case 8:
          return "Importado para NFe";
        case 9:
          return "Produção";
        case 10:
          return "Entregue";
        case null:
          return "Editando";
        default:
          return "";
      }
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o orçamento?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/orcamento/delete", {
                id_orcamento: data.id_orcamento,
              });
              this.$refs.orcamentoList.refresh();
              this.$toast.success("Orçamento excluído");
            }
          });
      });
    },
    async atualizarStatus() {
      const idsOrcamentos = this.actionsStore?.selectedRows.map(
        (orcamento) => ({ id_orcamento: orcamento.id_orcamento })
      );
      if (!this.statusSelected) {
        this.$toast.error("Selecione o status");
        return;
      }
      this.addLoadingToButton("updateOrcamento");
      try {
        await this.$http.post("/orcamento/update-status", {
          orcamentos: idsOrcamentos,
          status: this.statusSelected.id,
        });
        this.$bvModal.hide("atualizarOrcamento");
        this.$toast.success("Orçamento(s) atualizado(s) com sucesso!");
        this.statusSelected = null;
        this.removeLoadingToButton("updateOrcamento");
        this.$refs.orcamentoList.refresh();
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
      } catch (error) {
        this.removeLoadingToButton("updateOrcamento");
        console.log(error);
      }
    },
    async getStatusOrcamentos() {
      const { data } = await this.$http.post("/orcamento/status");
      this.orcamentoStatus = data;
    },
    async custoOrcamento() {
      const { id_orcamento } = this.actionsStore?.selectedRows[0];

      this.addLoadingToButton("doubleOrcamento");

      try {
        const response = await this.$http.post("/orcamento/custo", {
          id_orcamento,
        });

        if (response["data"]["url_custo_orcamento"]) {
          window.open(response["data"]["url_custo_orcamento"], "_blank");
        }

        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Custo do Orçamento gerado com sucesso!");
        this.$refs.orcamentoList.refresh();
        this.$bvModal.hide("custoOrcamento");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("doubleOrcamento");
    },
    async duplicar() {
      const { id_orcamento } = this.actionsStore?.selectedRows[0];

      this.addLoadingToButton("doubleOrcamento");

      try {
        await this.$http.post("/orcamento/duplicar", {
          id_orcamento,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Orçamento duplicado com sucesso!");
        this.$refs.orcamentoList.refresh();
        this.$bvModal.hide("duplicarOrcamento");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("doubleOrcamento");
    },
  },
  dateRangeFilter(data, filterString) {
    let dateRange = filterString.split("to");
    let startDate = Date.parse(dateRange[0]);
    let endDate = Date.parse(dateRange[1]);
    return (data =
      Date.parse(data) >= startDate && Date.parse(data) <= endDate);
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    this.getStatusOrcamentos();

    let inputs = ['input[placeholder="Data"]'];
    inputs.forEach(function (input) {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        allowInput: true,
        locale: Portuguese,
      });
    });
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #ee7361 !important;
}

.headerColumn {
  text-align: start;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #ee7361 !important;
  color: #ee7361 !important;
}
</style>
