<template>
  <div class="container-fluid p-0">
    <template>
      <Keypress key-event="keyup" :prevent-default="true" :key-code="teclasEnum.F2" @success="buscarProduto" />
      <Keypress key-event="keyup" :prevent-default="true" :key-code="teclasEnum.F4"
        @success="handleShowPagamentoModal({ transmitir: true, isSave: false })" />
      <Keypress key-event="keyup" :prevent-default="true" :key-code="teclasEnum.F8"
        @success="handleShowPagamentoModal({ transmitir: false, isSave: false })" />
      <Keypress key-event="keyup" :prevent-default="true" :key-code="teclasEnum.F1" @success="handleSairDaVenda" />
    </template>

    <b-sidebar id="sidebar-caixa" :width="'400px'" :title="caixaInfo.caixa" backdrop-variant="dark" backdrop right
      shadow>
      <template #default="{ hide }">
        <div class="px-2 py-2">
          <div style="width: 100%;" class="mb-2">
            <button style="width: 100%;" class="btn btn-success btn-hover-dark font-weight-bold"
              v-b-toggle.sidebar-sangria-suprimento @click="() => {
        caixaOperacao = 'Suprimento';
        hide();
      }">
              Suprimento
            </button>
          </div>
          <div style="width: 100%;" class="mb-2">
            <button style="width: 100%;" class="btn btn-warning btn-hover-dark  font-weight-bold"
              v-b-toggle.sidebar-sangria-suprimento @click="() => {
        caixaOperacao = 'Sangria';
        hide();
      }">
              Sangria
            </button>
          </div>

          <div class=" border-bottom mt-2"
            style="display: flex; flex-direction: row; gap: 1.4rem; text-align: end; justify-content: space-between;">
          </div>
          <div v-if="caixaInfo.pagamentos.length > 0" class="my-4" style="text-align: center;">
            <p class="mb-0 font-weight-bolder">Formas de pagamento utilizadas</p>
          </div>
          <div class="py-2 px-6 border-bottom" v-for="pagamento in caixaInfo.pagamentos"
            v-bind:key="pagamento.descricao"
            style="display: flex; flex-direction: row; gap: 1.4rem; text-align: end; justify-content: space-between;">
            <p class="mb-0 font-weight-bold">{{ pagamento.descricao }} R$</p> <span class="font-weight-bolder">
              {{ numberToReal(pagamento.total) }}</span>
          </div>
          <div class="py-4 px-6 border-top border-bottom mt-2">
            <div
              style="display: flex; flex-direction: row; gap: 1.4rem; text-align: end; justify-content: space-between;">
              <p class="mb-1" v-b-tooltip.hover.top="'Soma de todas as vendas feitas'">Total de vendas
                R$</p> <span style="font-size: 16px;">{{
        numberToReal(caixaInfo.total)
      }}</span>
            </div>
            <div style="display: flex; flex-direction: row; gap: 1.4rem;  justify-content: space-between;">
              <p class="text-danger mb-1">Total de vendas canceladas R$(-) </p> <span class="text-danger"
                style="font-size: 16px;">{{
          numberToReal(caixaInfo.total_vendas_canceladas) }}</span>
            </div>
            <div
              style="display: flex; flex-direction: row; gap: 1.4rem; text-align: end; justify-content: space-between;">
              <p class="text-success mb-1">Abertura de caixa R$(+) </p> <span class="text-success"
                style="font-size: 16px;">{{
        numberToReal(caixaInfo.dados_abertura.valor_abertura) }}</span>
            </div>
            <div
              style="display: flex; flex-direction: row; gap: 1.4rem; text-align: end; justify-content: space-between;">
              <p class="text-success mb-1">Suprimento R$(+) </p> <span class="text-success" style="font-size: 16px;">{{
        numberToReal(caixaInfo.suprimento) }}</span>
            </div>
            <div
              style="display: flex; flex-direction: row; gap: 1.4rem; text-align: end; justify-content: space-between;">
              <p class="text-danger mb-1">Sangria R$(-) </p> <span class="text-danger" style="font-size: 16px;">{{
        numberToReal(caixaInfo.sangria) }}</span>
            </div>
          </div>
          <div class="py-4 px-6 border-bottom mt-2"
            style="display: flex; flex-direction: row; gap: 1.4rem; text-align: end; justify-content: space-between;">
            <p class="mb-0 font-weight-bold"
              v-b-tooltip.hover.top="'Soma de todas vendas feitas no Dinheiro/Cheque + Abertura de Caixa + Suprimento - Sangria'">
              Total
              na gaveta R$(=) </p> <span class="font-weight-bolder" style="font-size: 16px;">{{
        numberToReal(caixaInfo.total_da_gaveta) }}</span>
          </div>
          <div class="py-4 px-6 border-bottom mt-2"
            style="display: flex; flex-direction: row; gap: 1.4rem; text-align: end; justify-content: space-between;">
            <p class="mb-0 font-weight-bold"
              v-b-tooltip.hover.top="'Soma das vendas que não foram recebidas no Dinheiro (Á vista)'">Total no caixa
              geral
              R$(=) </p> <span class="font-weight-bold" style="font-size: 16px;">{{
        numberToReal(caixaInfo.total_venda_nao_gaveta) }}</span>
          </div>
        </div>
      </template>
      <template #footer="{ hide }">
        <div style="width: 98%;" class="ml-2 mb-2">
          <button style="width: 98%;" class="btn btn-danger btn-hover-dark font-weight-bold" @click="() => {
        fecharCaixa();
        hide();
      }">
            Fechar
          </button>
        </div>
      </template>
    </b-sidebar>

    <b-sidebar id="sidebar-sangria-suprimento" :title="caixaOperacao" backdrop-variant="dark" backdrop right shadow>
      <div class="px-2 py-2">
        <div style="width: 100%;" class="mb-2">
          <b-form-group label-for="caixaValorOperacao" label="Valor R$">
            <VMoney class="form-control" id="caixaValorOperacao" name="caixaValorOperacao" v-model="caixaValorOperacao"
              v-bind="configMask" :key="keyToRender" v-on:keyup.native.enter="() => {
        salvarSangriaSuprimento(caixaOperacao);
        $root.$emit('bv::toggle::collapse', 'sidebar-sangria-suprimento')
      }" />
          </b-form-group>
          <b-form-group label-for="descricaoOperacao" label="Descrição">
              <b-form-input v-model="descricaoOperacao"></b-form-input>
          </b-form-group>
        </div>
        <div style="width: 100%;" class="mb-2">
          <b-form-group label-for="sangria_suprimento_comprovante" label="Imprimir comprovante">
            <span class="switch switch-icon">
              <label v-b-tooltip.hover.right="'Imprimir comprovante?'">
                <input type="checkbox" v-model="sangria_suprimento_comprovante" />
                <span></span>
              </label>
            </span>
          </b-form-group>
        </div>
      </div>
      <template #footer="{ hide }">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <button style="width: 100%;" class="btn btn-primary btn-hover-dark  font-weight-bold" @click="() => {
        salvarSangriaSuprimento(caixaOperacao);
        hide();
      }">
            Salvar
          </button>
        </div>
      </template>
    </b-sidebar>

    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div>
        <b-row class="mb-2">
          <b-col cols="1" class="mt-1">
            <b-dropdown id="dropdown-dropright" dropright variant="primary" v-b-tooltip.hover.right
              title="Mudar tipo de filtro">
              <template #button-content>
                <FilterOutline :size="20" />
              </template>
              <b-dropdown-item @click="() => { typeFilter = 1 }" :class="typeFilter === 1 ? 'bg-primary' : ''">
                <p :class="typeFilter === 1 ? 'text-white' : ''">
                  Começando com
                </p>
              </b-dropdown-item>
              <b-dropdown-item @click="() => { typeFilter = 2 }" :class="typeFilter === 2 ? 'bg-primary' : ''">
                <p :class="typeFilter === 2 ? 'text-white' : ''">
                  Terminando com
                </p>
              </b-dropdown-item>
              <b-dropdown-item @click="() => { typeFilter = 3 }" :class="typeFilter === 3 ? 'bg-primary' : ''">
                <p :class="typeFilter === 3 ? 'text-white' : ''">
                  Contendo
                </p>
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col style="display: flex; align-items: center; margin-left: 20px" v-if="caixaAberturaHorario && !isCaixaFechadoManual">
            <h4 class="m-0 mt-2">Caixa aberto às {{ caixaAberturaHorario }} </h4>
          </b-col>
          <b-col style="display: flex; justify-content: end; align-items: center;">
            <button class="btn btn-primary font-weight-bold mr-2" ref="import-orcamento-button"
              v-b-tooltip.top="'Somente irá importar com status: Autorizado'" @click="buscarPedidos">
              Importar Pedido
            </button>
            <button class="btn btn-primary font-weight-bold mr-2" v-b-tooltip="'Mais opções'" v-b-toggle.sidebar-caixa
              v-if="!isCaixaFechadoManual && store.aberturaCaixa">
              <DotsHorizontal :size="20" />
            </button>
            <button v-if="isCaixaFechadoManual && store.aberturaCaixa" class="btn btn-primary font-weight-bold mr-2"
              v-b-tooltip="'Abrir caixa'" v-b-modal.caixa-modal>
              Abrir Caixa
            </button>
            <button class="btn btn-primary font-weight-bold"
              v-b-tooltip="!isFullScreen ? 'Entrar em Fullscreen' : 'Sair de Fullscreen'" @click="() => {
        if (!isFullScreen) requestFullScreen()
        else closeFullscreen()
      }">
              <Fullscreen :size="20" v-if="!isFullScreen" />
              <Close :size="20" v-else />
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <b-row align-v="center">
              <b-col>
                <CardBusca>
                  <template v-slot:quantidade>
                    <Money 
                      ref="quantidade" 
                      id="quantidade" 
                      :options="quantityOptions" 
                      style="text-align: right"
                      labelBold 
                      :onEnter="informarValor" 
                      v-model="quantidade" 
                      :label="'1'" />
                  </template>
                  <template v-slot:valor>
                    <Money 
                      ref="valor" 
                      id="valor" 
                      :options="priceOptions" 
                      style="text-align: right" 
                      labelBold
                      :onEnter="adicionarProduto" 
                      v-model="price" 
                      :label="'2'" />
                  </template>
                  <template v-slot:addButton>
                    <button @click="() => adicionarProduto()">
                      <Plus class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9" v-if="windowWidth > 768" />
                      <button v-else class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg mt-9">
                        + Adicionar
                      </button>
                    </button>
                  </template>
                  <template v-slot:formulario>
                    <Select placeholder="Informe o código, descrição, código de barras ou referência do produto"
                      labelBusca="descricao" v-model="produto" ref="buscarProduto" :options="produtos"
                      inputClass="busca-produto" :change="setProduto" :search="onSearch" id="buscarProduto">
                      <template slot="options" slot-scope="props">
                        <div v-if="!props.data.isKit" class="selected" style="text-transform: uppercase;">
                          {{ props.data.codigo }} - {{ props.data.descricao }}
                          <br />
                          R$
                          {{
        numberToReal(parseFloat(props.data.preco), 'preco') +
        " | Qtd.: " +
        numberToReal(parseFloat(props.data.quantidade), 'quantidade')
      }}
                        </div>
                        <div v-else class="d-flex justify-content-between">
                          <div>
                            {{ props.data.codigo }} - {{ props.data.descricao }}
                            <span id="kitInfo" v-if="props.data.isKit" :title="getKitInfo(props.data)"
                              v-b-tooltip.hover.html.right>
                              <InformationOutline :size="14" />
                            </span>
                          </div>
                          <span class="text-muted">{{ !props.data.isKit ? `QTD: ${props.data.quantidade}` : `R$
                            ${numberToReal(props.data.totalPrice)}` }}</span>
                        </div>
                      </template>
                      <template slot="selected">
                        <div class="selected" style="text-transform: uppercase">
                          <h3 class="font-weight-boldest" style="font-size: 2.05rem">
                            {{ ultimoProduto.descricao }}
                          </h3>
                        </div>
                      </template>
                    </Select>
                  </template>
                </CardBusca>
              </b-col>
            </b-row>
            <CardItensVenda :total="total" :valorPago="valorPago" :acrescimo="valorUS(venda.extraValue)"
              :desconto="valorUS(venda.discount)" :substractQtd="substractQtd" :addQtd="addQtd" />
          </b-col>

          <b-col col lg="4">
            <div class="card card-custom right-card">
              <div class="justify-content-center pl-0py-8 px-8 py-md-10 px-md-0">
                <div class="col-md-12">
                  <div class="justify-content-between flex-column flex-md-row font-size-lg ">
                    <div class="d-flex flex-column mb-10 mb-md-0">
                      <div>
                        <b-row class="form-group">
                          <b-col>
                            <div class="accordion" role="tablist">
                              <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                  <b-button block v-b-toggle.accordion-2
                                    variant="primary">Descontos/Acréscimos</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                  <b-card-body>
                                    <b-row>
                                      <b-col>
                                        <label>Desconto</label>
                                        <b-input-group>
                                          <template #prepend>
                                            <b-input-group-text role="button" class="font-weight-bold"
                                              @click="setIsPercent">{{ tipoDescontoValue === 2 ? '%' :
        '$'
                                              }}</b-input-group-text>
                                          </template>
                                          <NumberInput label="desconto" :defaultValue="valueDiscount"
                                            @changeValue="setDesconto" :onEnter="saveDescontoAcrescimo"
                                            :disabled="(valueExtraValue !== 0) ? true : false" />
                                        </b-input-group>
                                        <small>Enter p/ adicionar valor</small>
                                      </b-col>
                                      <b-col>
                                        <label>Acréscimo</label>
                                        <b-input-group>
                                          <template #prepend>
                                            <b-input-group-text role="button"
                                              class="font-weight-bold">$</b-input-group-text>
                                          </template>
                                          <NumberInput label="acrescimo" :defaultValue="valueExtraValue"
                                            @changeValue="setAcrescimo" :disabled="(valueDiscount !== 0) ? true : false"
                                            :onEnter="saveDescontoAcrescimo" />
                                        </b-input-group>
                                        <small>Enter p/ adicionar valor</small>
                                      </b-col>
                                    </b-row>
                                  </b-card-body>
                                </b-collapse>
                              </b-card>
                              <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                  <b-button block v-b-toggle.clienteTab variant="primary">Cliente</b-button>
                                </b-card-header>
                                <b-collapse id="clienteTab" accordion="clienteAcordion" role="tabpanel">
                                  <b-card-body>
                                    <b-row class="mb-5" style="justify-content: space-between;">
                                      <div style="width: 49%;">
                                        <button ref="add_buyer" class="btn btn-primary font-weight-bold"
                                          v-b-modal.modal-client style="font-size: 11px; width: 100%;">
                                          Adicionar cliente
                                        </button>
                                      </div>
                                      <div style="width: 49%;">
                                        <button ref="add_buyer" class="btn btn-primary font-weight-bold"
                                          v-b-modal.modal-client-new style="font-size: 11px; width: 100%;">
                                          Não cadastrado
                                        </button>
                                      </div>
                                    </b-row>
                                    <b-row class="pb-5">
                                      <b-col>
                                        <div class="font-weight-bolder font-size-lg mb-3">
                                          Nome Cliente
                                        </div>
                                        <div>
                                          {{ valueCliente?.nome ?? '' }}
                                        </div>

                                      </b-col>
                                      <b-col>
                                        <div class="font-weight-bolder font-size-lg mb-3">
                                          CPF/CNPJ
                                        </div>
                                        <div>
                                          {{ formatCpfCnpj(valueCliente?.cnpj_cpf ?? '') }}
                                        </div>
                                      </b-col>
                                    </b-row>
                                    <b-row align-h="center" v-if="valueCliente?.nome">
                                      <b-col cols="6">
                                        <button ref="add_buyer"
                                          class="btn btn-secondary font-weight-bold border border-primary"
                                          style="font-size: 11px;" @click="removeCliente">
                                          <div style="display: flex; align-items: center; gap: 4px">
                                            <p class="mb-0">Remover cliente </p>
                                            <TrashCanIcon :size="14" />
                                          </div>
                                        </button>
                                      </b-col>
                                    </b-row>
                                    <br>
                                    <b-row v-if="limiteCreditoAtigindo"  align-h="center">
                                      <b-col cols="12">
                                        <span class="label label-lg label-inline label-light-danger">Limite de crédito atingido</span>
                                        <br>
                                        <br>
                                        <span><strong
                                            >Limite: R$ {{ numberToReal(valorLimiteCredito, 2) }}</strong
                                          ></span
                                        >
                                        /
                                        <span
                                          v-b-tooltip.righttop="
                                            'O limite de crédito considera contas a receber na situação (Em aberto)'
                                          "
                                          ><strong
                                            >Atingido: R$
                                            {{ numberToReal(valorLimiteCreditoAtingido, 2) }}</strong
                                          ></span
                                        >
                                      </b-col>
                                    </b-row>
                                  </b-card-body>
                                </b-collapse>
                              </b-card>
                              <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                  <b-button block v-b-toggle.vendedorTab variant="primary">Vendedor</b-button>
                                </b-card-header>
                                <b-collapse id="vendedorTab" accordion="clienteAcordion" role="tabpanel">
                                  <b-card-body>
                                    <b-row>
                                      <b-col>
                                        <b-form-group label="" label-for="vendedor">
                                          <VueSelect :filterable="false" label="vendedor" v-model="venda.vendedor"
                                            class="busca-vendedor" ref="buscaVendedor" :options="vendedores"
                                            @search="onSearchVendedor">
                                            <template slot="no-options">
                                              Informe o nome do vendedor
                                            </template>
                                            <template slot="option" slot-scope="option">
                                              <div class="d-center">
                                                {{ option.nome }}
                                              </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                              <div class="selected d-center">
                                                {{ option.nome }}
                                              </div>
                                            </template>
                                          </VueSelect>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </b-card-body>
                                </b-collapse>
                              </b-card>
                              <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                  <b-button block v-b-toggle.observationTab variant="primary">Informações
                                    Adicionais</b-button>
                                </b-card-header>
                                <b-collapse id="observationTab" accordion="clienteAcordion" role="tabpanel">
                                  <b-card-body>
                                    <b-row>
                                      <b-col>
                                        <b-form-group label="" label-for="informacoesAdicionais">
                                          <v-textarea full-width rows="4" v-model="venda.observation"></v-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </b-card-body>
                                </b-collapse>
                              </b-card>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="justify-content-center py-6 px-6 py-md-6 px-md-0">
                <div class="col-md-10">
                  <div class="d-flex flex-column text-md-right">
                    <span class="font-size-lg font-weight-bolder mb-1">SUBTOTAL</span>
                    <span class="font-size-h2 font-weight-boldest mb-1">R$ {{ valorReal(valorTotal) }}</span>
                    <span class="text-success" v-if="descontoAplicado">Desconto: {{ tipoDescontoValue === 1 ? `R$
                      ${valorReal(valueDiscount)}` : `${valorReal(valueDiscount)}%`
                      }}</span>
                    <span class="text-danger" v-if="acrescimoAplicado">Acréscimo: {{ tipoAcrescimoValue === 1 ? `R$
                      ${valorReal(valueExtraValue)}` : `${valorReal(valueExtraValue)}%`
                      }}</span>
                    <span class="font-size-h4 text-success" v-if="valorPago > 0">Valor Pago: R$ {{ valorReal(valorPago)
                      }}</span>
                    <span class="font-size-h4" v-if="troco > 0 && !valorPago">Troco: R$
                      {{
        valorReal(troco)
      }}</span>
                    <span class="font-size-h4" v-if="(parseFloat(valorPago) - parseFloat(total)) > 0">Troco: R$
                      {{
        valorReal(parseFloat(valorPago) - parseFloat(total))
      }}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="venda-footer">
          <b-dropdown id="dropdown-dropup" dropup text="MF" class="m-2" 
            :disabled="isLoadingSubmit"
            v-if="this.store.pafNFCe">
            <b-dropdown-text style="width: 240px; font-size: 12px;">
              <b-button  size="sm" v-b-modal.identificacaoPAFNFCe>Identificação do PAF-NFC-e</b-button>
            </b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-text>
              <p style="margin-left: 10px;">Registros do PAF-NFC-e</p>
              <b-button  size="sm" v-b-modal.estoqueTotal>Estoque total</b-button>
              <!-- <b-button  size="sm">Estoque parcial</b-button> -->
              <!-- <b-button  size="sm">Saídas identificadas</b-button> -->
            </b-dropdown-text>
          </b-dropdown>
          <button :disabled="isLoadingSubmit" style="margin-right: 5px" type="button" class="btn btn-light-success"
            @click="handleShowPagamentoModal({ transmitir: true, isSave: false })" v-if="total > 0">
            F4 - Emitir NFC-e
          </button>
          <button :disabled="isLoadingSubmit" style="margin-right: 5px" type="button" class="btn btn-primary"
            @click="handleShowPagamentoModal({ transmitir: false, isSave: false })" v-if="total > 0 && !this.store.pafNFCe">
            F8 - Finalizar Pré-Venda
          </button>
          <button :disabled="isLoadingSubmit" style="margin-right: 5px" type="button" class="btn btn-light-warning"
            @click="handleSalvarVenda(false)" v-if="total > 0">
            Salvar
          </button>
          <button :disabled="isLoadingSubmit" type="button" class="btn btn-light-danger" @click="handleSairDaVenda">
            F1 - Cancelar
          </button>
        </div>
      </div>
    </b-form>

    <b-modal id="modal-calendar" title="Ajustar vencimento" size="sm" centered>
      <div class="mx-auto">
        <b-calendar v-model="vencimentoSelected" locale="pt-BR"></b-calendar>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="salvarVencimento" ref="transmitir_nfe">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="identificacaoPAFNFCe" title="Identificação PAF-NFC-e" centered>
      <b-form class="form">
        <b-row align-h="center">
          <b-col>
            <strong> CNPJ: </strong> 49.050.590/0001-56 <br>
            <strong> Nome Fantasia: </strong> NUTS TECNOLOGIA<br>
            <strong> Endereço: </strong> R OSWALDO VALENTIM ZANDAVALLI, 431, CENTRO, CONCÓRDIA - SC <br>
            <strong> Telefone: </strong> 49 3550 0600 <br>
            <strong> Contato: </strong> JANIR DE ABREU<br>
            <strong> Nome Comercial: </strong> NUTS <br>
            <strong> Versão:</strong> 01.00<br>
            BANCO DE DADOS NA NUVEM<br>
            PAF-NFC-e NUVEM<br>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="() => {
        cancel()
        }"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal id="estoqueTotal" title="PAF NFCe - Estoque total" centered>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="primary"
          @click="gerarPAFEstoqueTotal"
          ref="gerarPAFEstoqueTotal"
          class="mr-5"
        >
          Gerar arquivo
        </b-button>
        <b-button @click="() => {
        cancel()
        }"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal id="importarPedido" title="Importar Pedido" centered>
      <b-form class="form">
        <b-row align-h="center">
          <b-col>
            <b-form-group :key="keyToReRender" label="Pedido" label-for="pedido">
              <VueSelect multiple ref="pedido" :filterable="false" :options="pedidosToImport" v-model="selectedPedidos"
                @search="onSearchPedido">
                <template slot="no-options">Busque pelo número do pedido</template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.numero }} - {{ option.cliente }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="importarPedido" ref="importar_pedido">
          Importar
        </b-button>
        <b-button @click="() => {
        cancel()
        selectedPedidos = null
      }"> Cancelar </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-pagamento" title="Ajustar pagamento" size="sm" centered>
      <div class="mx-auto">
        <b-form class="form" @submit.stop.prevent="salvarPagamento">
          <VMoney @input="changePagamentoEdited" class="form-control" id="valorPagamento" name="valorPagamento"
            v-model="pagamentoEditado" v-bind="configMask" />
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="salvarPagamento" ref="transmitir_nfe">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-client-new" title="Adicionar cliente" size="md" centered>
      <b-form-group id="name" label-for="name" label="Nome">
        <b-form-input v-model="newBuyer" />
      </b-form-group>
      <b-form-group id="cnpj_cpf" label-for="cnpj_cpf" label="CPF/CNPJ">
        <Input ref="newcpfcnpj" id="newcpfcnpj" :mask="['###.###.###-##', '##.###.###/####-##']" labelBold
          v-model="newBuyerCnpjCpf" />
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="addBuyer" ref="transmitir_nfe">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-client" title="Adicionar cliente" size="md" centered>
      <b-form-group id="name" label-for="name" label="Nome:">
        <VueSelect ref="buscarCliente" :filterable="false" @input="setCliente"
          v-model="selectedCliente.nome" :options="clientes" @search="onSearchCliente">
          <template slot="no-options">
            Informe o nome do cliente
          </template>
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.nome }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.label }}
            </div>
          </template>
        </VueSelect>
      </b-form-group>
      <b-form-group id="cnpj_cpf" label-for="cnpj_cpf" label="CPF/CNPJ">
        <Input ref="newcpfcnpj" id="newcpfcnpj" :mask="['###.###.###-##', '##.###.###/####-##']" labelBold
          :value="selectedCliente?.cnpj_cpf ?? ''" />
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="saveCliente" ref="salvar-cliente">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-pos-venda" :title="modalTitle" size="md" centered no-close-on-backdrop no-close-on-esc>
      <b-row class="text-center" v-if="posStep === 0">
        <b-col class="mt-5">
          <h3>Aguardando pagamento</h3>
        </b-col>
      </b-row>
      <b-row class="text-center" v-if="posStep === 1">
        <b-col cols="12">
          <h3>Selecione o equipamento para finalizar a transação</h3>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <select name="equipamento" id="equipamento" v-model="equipamentosSelecionado" class="form-control">
              <option v-for="(e, index) in equipamentosPos" :value="e" :key="'equipamento' + index" @input="setEquipamento">
                {{ e.descricao }} - {{ e.serial_pos }}
              </option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-button size="sm" variant="primary" @click="solicitarPagamentoPos">
            Solicitar pagamento
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center" v-if="posStep === 2">
        <b-col>
          <h3>Atualizando dados da {{ isNfce ? 'NFCe' : 'Pré-venda' }}</h3>
        </b-col>
      </b-row>
      <b-row class="text-center" v-if="posStep === 3">
        <b-col>
          <h3>Imprimindo {{ isNfce ? 'NFCe' : 'Pré-venda' }}...</h3>
        </b-col>
      </b-row>
      <b-row class="text-center" v-if="posStep === 5">
        <b-col>
          <h3>Criando a venda...</h3>
        </b-col>
      </b-row>
      <b-row class="text-center" v-if="posStep === 6">
        <b-col>
          <h3>Comunicando com TEFWeb...</h3>
        </b-col>
        <b-col md="12">
          <b-button size="sm" variant="primary" @click="cancelarPagamentoPos">
            Cancelar solicitação
          </b-button>
        </b-col>
      </b-row>
      <b-row class="text-center mt-5 mb-5" v-if="!isLoadingPosData">
        <b-col>
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel, hide }">
        <div></div>
      </template>
    </b-modal>

    <b-modal id="modal-pos-cartao" title="Informação de dados do Cartão" size="md" centered>
      <b-row>
        <b-col>
          <b-form-group label-for="Administradora" label="Administradora" class="mr-5">
            <VueSelect label="bandeira_" :filterable="false" v-model="selectedAdmnistradora" :options="administradoras"
              @search="onSearchAdmin">
              <template slot="no-options">
                Selecione a admnistradora
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.nome }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-for="Bandeira do cartão" label="Bandeira do cartão" class="mr-5">
            <VueSelect label="bandeira_" :filterable="false" v-model="selectedBandeira" :options="bandeiras">
              <template slot="no-options">
                Selecione a bandeira
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.descricao }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.descricao }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-for="Código de transação (NSU)" label="Código de transação (NSU)" class="mr-5">
            <b-form-input v-model="codigoNsu" id="nsu" autocomplete="off" maxLength="20" />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
        <b-button size="sm" variant="secondary" @click="() => {
        ok();
        handleSaveCardInfo();
      }">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal @shown="handleOpenModal" id="modal-forma-pagamento" title="Defina a forma de pagamento" size="md" static
      centered>
      <b-row class="form-group" style="margin-top: 20px">
        <b-col class="busca" col md="6">
          <div class="font-weight-bolder font-size-lg mb-3">
            Forma de Pagamento
          </div>
          <VueSelect label="descricao" v-model="pagamento" class="busca-cliente" ref="buscaPagamentos"
            :options="pagamentos" @input="changePagamentos">
          </VueSelect>
        </b-col>
        <b-col md="6">
          <div class="font-weight-bolder font-size-lg mb-3" ref="teste2">
            Valor
          </div>
          <Money ref="valorPagamentoModal" id="valorPagamentoModal" labelBold inputClass="form-control input"
            :onEnter="adicionarPagamento" v-model="valorPgto" />

        </b-col>
      </b-row>
      <b-row style="display: flex; justify-content: center;">
        <b-button size="sm" variant="primary" @click="adicionarPagamento">
          Adicionar Pagamento
        </b-button>
      </b-row>
      <b-row class="form-group" v-if="listaPagamentos.length > 0">
        <b-col class="busca">
          <b-row v-for="(pagamento, key) in listaPagamentos" :key="'pagamento' + key">
            <div style="
                                                    width: 100%; 
                                                    display: flex; 
                                                    align-items: center; 
                                                    justify-content: space-between; 
                                                    padding: 0.5rem 1rem;
                                                    border-top: 1px solid #ededed;
                                                    margin-top: 2rem;
                                            ">
              <p class="font-weight-bolder font-size-lg mb-0">{{ pagamento.descricao }}</p>
              <a v-b-tooltip="'Remover pagamento'" href="javascript:" @click="removePagamento(key)" class="remove-pgto">
                <Close />
              </a>
            </div>
            <div style="width: 100%">
              <TableApp item="pagamento" subTitulo="" :data="pagamento.range" :fields="fields" classFilterColumn="valor"
                ref="pagamentoTable" v-if="pagamento !== null && pagamento?.descricao !== 'Nenhum'">
                <template slot="customRow" scope="props">
                  <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'id'">
                    <div class="d-flex align-items-center">
                      <div class="font-weight-bold text-muted">
                        {{ props.rowData.originalIndex + 1 }}
                      </div>
                    </div>
                  </span>
                  <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'valor'">
                    <div class="d-flex align-items-center">
                      <div v-b-modal.modal-pagamento @click="changePagamento(props.rowData.originalIndex, key)"
                        class="font-weight-bold text-muted cursor-pointer">
                        {{ numberToReal(props.rowData.value) }}
                      </div>
                    </div>
                  </span>
                  <span slot="quantidade" class="pl-0 pt-8" v-if="props.field == 'vencimento'">
                    <div class="d-flex align-items-center">
                      <div v-b-modal.modal-calendar class="font-weight-bold text-muted cursor-pointer"
                        @click="selectVencimento(props.rowData.originalIndex, props.rowData.date, key)">
                        {{ props.rowData.date | moment("DD/MM/YYYY") }}
                      </div>
                    </div>
                  </span>
                </template>
              </TableApp>
            </div>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="border-top mt-2">
        <div class="col-md-10">
          <div class="d-flex justify-content-center flex-column mt-5">
            <span class="font-size-lg font-weight-bolder mb-1">SUBTOTAL</span>
            <span class="font-size-h2 font-weight-boldest mb-1">R$ {{ valorReal(valorTotal) }}</span>
            <span class="text-success" v-if="descontoAplicado">Desconto: {{ tipoDescontoValue === 1 ? `R$
              ${valorReal(valueDiscount)}` : `${valorReal(valueDiscount)}%`
              }}</span>
            <span class="text-danger" v-if="acrescimoAplicado">Acréscimo: {{ tipoAcrescimoValue === 1 ? `R$
              ${valorReal(valueExtraValue)}` : `${valorReal(valueExtraValue)}%`
              }}</span>
            <span class="font-size-h4 text-success" v-if="valorPago > 0">Valor Pago: R$ {{ valorReal(valorPago)
              }}</span>
            <span class="font-size-h4" v-if="troco > 0 && !valorPago">Troco: R$
              {{
        valorReal(troco)
      }}</span>
            <span class="font-size-h4" v-if="(parseFloat(valorPago) - parseFloat(total)) > 0">Troco: R$
              {{
        valorReal(parseFloat(valorPago) - parseFloat(total))
      }}</span>
          </div>
        </div>
        <div>
          <input style="caret-color: transparent; margin-top: -20px; position: absolute;" ref="finishModal"
            @keypress.enter.prevent="() => {
          fromPagamentoModal = true;
          justSave ? handleSalvarVenda(false) : handleFinalizar(transmitir);
        }" />
        </div>
      </b-row>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
        <b-button size="sm" variant="secondary" @click="() => {
        fromPagamentoModal = true;
        justSave ? handleSalvarVenda(false) : handleFinalizar(transmitir);
      }">
          Finalizar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="caixa-modal" title="" size="md" centered no-close-on-backdrop no-close-on-esc>
      <template #modal-header="{ ok, cancel }">
        <h4 class="text-dark">Abertura de caixa</h4>
      </template>
      <b-row>
        <b-col class="mt-5">
          <b-form-group label-for="caixaValorAbertura" label="Valor Caixa R$">
            <VMoney class="form-control" id="caixaValorAbertura" name="caixaValorAbertura" v-model="caixaValorAbertura"
              v-bind="configMask" />
          </b-form-group>
        </b-col>
        <b-col class="mt-5">
          <b-form-group label-for="caixaImpressaoComprovante" label="Imprimir comprovante">
            <span class="switch switch-icon">
              <label v-b-tooltip.hover.right="'Imprimir comprovante?'">
                <input type="checkbox" v-model="caixaImpressaoComprovante" />
                <span></span>
              </label>
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="() => {
        ok();
        abrirCaixa()
      }" ref="salvar-cliente">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <VariacaoProdutoModal :addProduto="() => adicionarProduto()" :produto="produto" @onAddProduto="({ newPreco, newQuantidade, newProduto }) => {
        price = `${numberToReal(formatNumberToFloat(newPreco))}`;
        quantidade = `${newQuantidade}`;
        produto = newProduto;
      }" />
  </div>
</template>
<script>

import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-loading-overlay/dist/vue-loading.css";
import { REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import { MONEY as settingMoney, priceOptions, quantityOptions } from "@/modules/Application";
import _ from "lodash";
import { CardBusca, CardItensVenda } from "../../Pdv/components/Cards";
import { notificationStore } from "@/notificationStore";
import PdvVendaProduto from "../../Pdv/pages/PdvVendaProduto";
import FormatMixin from "../../Application/mixins/FormatMixin";
import InstallmentsMixin from "../../Application/mixins/InstallmentsMixin";
import EmitenteMixin from "../../Application/mixins/EmitenteMixin";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import PagamentosMixin from "../../Pdv/mixins/PagamentosMixin";
import ConfiguracaoMixin from "../../Pdv/mixins/ConfiguracaoMixin";
import Money, { Select } from "../../Pdv/components/Form";
import PdvData from "../../Pdv/pages/PdvData";
import { Input } from "@/components/Form";
import TableApp from "@/components/Table/Table3.vue"
import Close from "vue-material-design-icons/Close";
import NumberInput from "../../../components/Form/NumberInput.vue";
import swal from "sweetalert";
import { TECLAS } from "../../Pdv/enums/Documento";
import { store } from "../storeVenda";
import { Money as VMoney } from "v-money";
import { onboardStore } from "../../../onboardStore";
import { SistemaMixin } from "@/modules/Application";
import FilterOutline from "vue-material-design-icons/Filter";
import Fullscreen from "vue-material-design-icons/Fullscreen.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import DotsHorizontal from "vue-material-design-icons/DotsHorizontal.vue";
import Plus from "vue-material-design-icons/PlusThick.vue";
import VariacaoProdutoModal from "@/components/VariacaoProdutoModal";
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";


export default {
  name: "app-venda",
  props: {
    url: {
      type: String,
    },
    nfe: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      store,
      sangria_suprimento_comprovante: true,
      caixaOperacao: null,
      pedidos: [],
      selectedPedidos: null,
      pedidosToImport: [],
      keyToRender: 0,
      caixaValorOperacao: null,
      descricaoOperacao: null,
      fromPagamentoModal: false,
      caixaImpressaoComprovante: false,
      caixaValorAbertura: null,
      caixaAberturaHorario: null,
      isCaixaFechadoManual: false,
      caixa: null,
      caixaInfo: {
        dados_abertura: {
          valor_abertura: 0,
        },
        pagamentos: [],
        pagamentos_usados: [],
        sangria: 0,
        suprimento: 0,
        total: 0,
        total_da_gaveta: 0,
        total_venda_na_gaveta: 0,
        total_venda_nao_gaveta: 0,
        total_vendas_canceladas: 0,
      },
      isFullScreen: false,
      onboardStore,
      quantityOptions,
      priceOptions,
      windowWidth: window.innerWidth,
      isNfce: false,
      venda: {},
      paymentCardListIndex: 0,
      notificationStore,
      posStep: 0,
      isLoadingPosData: false,
      formCartaoInfo: {
        administradora: { cnpj_cpf: null },
        bandeira: null,
        autorizacao: null
      },
      nota: {
        id: null,
        data: null,
      },
      teclasEnum: TECLAS,
      selectedCliente: {},
      quantity: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        precision: 2,
        masked: true,
      },
      vencimentoSelected: null,
      selectedPagamento: {
        pagamento: 0,
        parcelaIndex: 0,
      },
      codigoNsu: null,
      selectedBandeira: null,
      selectedAdmnistradora: null,
      newBuyer: null,
      newBuyerCnpjCpf: null,
      indexVencimento: 0,
      acrescimoAplicado: false,
      descontoAplicado: false,
      pagamentoEditado: 0,
      configMask: settingMoney,
      quantidade: 1,
      price: 1.00,
      produtos: [],
      ultimoProduto: {},
      produto: null,
      desconto: 0,
      acrescimo: 0,
      discount: 0,
      valueCliente: {},
      paymentsCartao: [],
      bandeiras: [],
      administradoras: [],
      clientes: [],
      pagamentos: [],
      pagamento: {},
      valorPagoVenda: 0,
      valorTotal: "0",
      listaPagamentos: [],
      listaFormaPagamentos: [],
      pagamentosEditados: [],
      listaMeiosPagamento: [],
      solicitarDadosCartao: false,
      valueMeioPagamento: null,
      pagamentoNoCartao: false,
      parcela: null,
      operacao: "",
      valorPgto: 0,
      tipos: [
        {
          value: 1,
          text: "Valor R$",
        },
        {
          value: 2,
          text: "Percentual",
        },
      ],
      isPercent: false,
      tipoAcrescimo: null,
      tipoDescontoValue: 1,
      tipoAcrescimoValue: 1,
      percentDiscount: 0,
      valueDiscount: 0,
      integracaoPos: false,
      equipamentosPos: [],
      equipamentosSelecionado: {},
      valueExtraValue: 0,
      percentExtraValue: 0,
      statusVenda: 1,
      typeFilter: 3,
      id: null,
      fields: [
        {
          label: "",
          field: "id",
          slot: true,
          width: "10%",
          sortable: false
        },
        {
          label: "Valor R$",
          field: "valor",
          slot: true,
          width: "45%",
          sortable: false
        },
        {
          label: "Vencimento",
          field: "vencimento",
          slot: true,
          sortable: false,
          width: "45%"
        },
      ],
      isLoadingSubmit: false,
      listaStatus: [
        {
          id: 1,
          descricao: "Editando",
        },
        {
          id: 7,
          descricao: "Finalizada (Movimenta estoque)",
        },
      ],
      statusSelecionado: null,
      informacoesComplementares: null,
      vendedores: [],
      filterable: false,
      transmitir: false,
      justSave: false,
      solicitadoPagamentoPos: false,
      limiteCreditoAtigindo: false,
      valorLimiteCredito: 0,
      valorLimiteCreditoAtingido: 0,
    };
  },
  mixins: [
    FormatMixin,
    PagamentosMixin,
    InstallmentsMixin,
    ConfiguracaoMixin,
    PessoaMixin,
    EmitenteMixin,
    SistemaMixin,
  ],
  components: {
    CardBusca,
    CardItensVenda,
    Money,
    VMoney,
    Select,
    VueSelect,
    Input,
    Close,
    TableApp,
    NumberInput,
    FilterOutline,
    Fullscreen,
    TrashCanIcon,
    Plus,
    DotsHorizontal,
    VariacaoProdutoModal,
    InformationOutline,
    Keypress: () => import("vue-keypress"),
  },
  watch: {
    'venda.observation'(newValue) {},
    pagamentos: {
      handler(listaPagamentos) {
        if (listaPagamentos.length === 0) return
        this.pagamento = this.pagamentos.find(pagamento => pagamento.descricao === 'À vista' || pagamento.descricao === 'A vista');
      }, deep: true
    },
    paymentCardListIndex: {
      handler(index) {
        if (index === 0) return;
        if (this.solicitarDadosCartao && index !== this.paymentsCartao.length) {
          this.$bvModal.hide('modal-pos-cartao');
          return;
        }
        this.handlePosPagamento();
      }
    },
    'notificationStore.lastPosNotification': {
      handler(value) {
        if (!_.isEmpty(value)) {
          this.handlePosPagamento();
        }
      }, deep: true,
    },
    nfe(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.venda = newValue;
        this.venda.observation = !this.nfe?.observation ? this.informacoesComplementares : this.nfe.observation
        this.store.productOfTradeCollection = newValue.productOfTradeCollection;
        this.listaPagamentos = newValue.paymentModeCollection;
        this.valueCliente = newValue.buyer;
        this.valueDiscount = this.valorReal(newValue.discount);
        this.valueExtraValue = this.valorReal(newValue.extraValue);
        this.statusVenda = newValue.status;
        this.tipoDescontoValue = newValue.tipoDescontoValue;
        this.tipoAcrescimoValue = newValue.tipoAcrescimoValue;
        this.isPercent = newValue.isPercent;
        this.tipoAcrescimo = newValue.tipoAcrescimo;
        this.statusSelecionado = newValue.statusSelecionado;
        this.venda.vendedor = newValue.vendedor;
      }
    },
    cliente(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valueCliente = newValue;
      }
    },
    pagamentosVenda(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.listaPagamentos = newValue;
      }
    },
    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorTotal = newValue;
        this.checkValorPago();
      }
    },
    quantidade(newValue, oldValue) {
      if (oldValue !== newValue) {
        const valueFormatted = newValue.replaceAll('.', '').replace(',', '.')
        const isAtacado = parseFloat(valueFormatted) >= parseFloat(this.produto?.quantidade_atacado) &&
          parseFloat(this.produto.preco_atacado) > 0

        this.price = isAtacado ?
          this.valorReal(this.roundTo2Digits(parseFloat(this.produto.preco_atacado), this.priceOptions.precision), true)
          :
          this.valorReal(this.roundTo2Digits(parseFloat(this.valorUS(this.produto.preco), true), this.priceOptions.precision));

        const total = parseFloat(valueFormatted) * parseFloat(this.price);
        this.produto.total = total
        this.produto.tipo_atacado = isAtacado
        this.produto.quantidade = newValue;
      }
    },
    valorPago(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorPagoVenda = newValue;
        this.checkValorPago();
      }
    },
    formasPagamento(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.listaFormaPagamentos = newValue;
      }
    },
    discount(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valueDiscount = newValue;
      }
    },
    extraValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valueExtraValue = newValue;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);

    this.getPagamentos();
    this.novaVenda();
  },
  async created() {
    this.setLoading(true);
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.nota.id = parseInt(this.$route.params.id);
    }
    const [currentConfigResponse] = await Promise.all([
      this.$http.post("/configuracoes/current-config", {
        modelo: 65,
      }),
    ]);
    const data = currentConfigResponse.data;
    this.solicitarDadosCartao = data[0]?.solicitarDadosCartao;

    if (data.length <= 0) {
      swal({
        title: "Verificamos que você ainda não realizou a configuração do ambiente",
        text: "Você foi redirecionado para o módulo de configuração",
        icon: "info",
        buttons: {
          confirm: {
            text: "Ok",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "ok") {
          swal.close();
          swal.stopLoading();
          return;
        }
        swal.close();
        swal.stopLoading();
      });
      this.$router.push({ name: "configuracoesNovo" });
    }
    this.serie = data[0].serie;
    this.informacoesComplementares = data[0].informacoesComplementares;
    this.venda.observation = !this.nfe?.observation ? this.informacoesComplementares : this.nfe.observation
    if (this.store.aberturaCaixa) {
      const { data: caixaData } = await this.$http.post('/caixa/get-caixa');
      if (!caixaData.caixa_aberto) {
        this.$bvModal.show('caixa-modal');
      } else {
        const momentData = this.$moment(caixaData.dados_caixa.data_abertura);
        const dataFormatted = momentData.format('HH:mm [de] DD/MM/YY')
        this.caixaAberturaHorario = dataFormatted;
        try {
          const { data: caixaMovimentacaoData } = await this.$http.post('/caixa/movimentacao');
          this.caixaInfo = caixaMovimentacaoData
        } catch (error) {
          console.log(error);
        }
      }
    }
    await this.getIntegracaoPos();
    this.setLoading(false);
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleOpenModal() {
      this.$refs.valorPagamentoModal.$el.querySelector("input").focus()
    },
    async buscarPedidos() {
      this.setLoading(true)
      try {
        const response = await this.$http.post('/pedido/pedidos-autorizados');
        this.pedidosToImport = response.data.sort((a, b) => {
          if (a.id_pedido < b.id_pedido) return -1;
          if (a.id_pedido > b.id_pedido) return 1;
          return 0;
        });
        this.$bvModal.show('importarPedido');
      } catch (error) {
        console.log(error)
      }
      this.setLoading(false)
    },
    onSearchPedido(search, loading) {
      loading(true);
      this.searchPedido(loading, search, this);
    },
    searchPedido: _.debounce(async (loading, search, vm) => {
      vm.pedidosToImport = [];
      await vm.$nextTick();
      if (!search) {
        loading(false);
        return;
      };
      const response = await vm.$http.post('/pedido/pedidos-autorizados', {
        numero: search
      });
      vm.pedidosToImport = _.cloneDeep(response.data);
      loading(false);
    }, 500),

    async importarPedido() {
      this.addLoadingToButton('importar_pedido')
      const pedidos = this.selectedPedidos.map(({ id_pedido }) => ({
        id_pedido,
      }))
      try {
        const { data } = await this.$http.post('/pedido/importar', {
          pedidos
        })
        const [formFaturamento, formProduto, formCliente] = this.extractDataFromImport(data);
        this.pedidos = pedidos;
        this.store.productOfTradeCollection = [
          ...this.store.productOfTradeCollection,
          ...formProduto.produtos
        ];
        this.venda.observation = data.observation;


        if (formCliente) {
          this.valueCliente = { ...formCliente };
          this.venda.buyer = this.valueCliente;
        }

        this.$toast.success("Pedido(s) importado(s) com sucesso!");
        this.$bvModal.hide('importarPedido');
        this.selectedPedidos = null,
          this.removeLoadingToButton('importar_pedido');
        await this.calcImposto();
      } catch (error) {
        this.$bvModal.hide('importarPedido');
        this.removeLoadingToButton('importar_pedido')
      }
    },

    changePagamento(parcelaIndex, pagamentoIndex) {
      this.selectedPagamento = {
        pagamento: pagamentoIndex,
        parcelaIndex: parcelaIndex,
      }
    },
    async loadCaixaInfo() {
      const { data: caixaMovimentacaoData } = await this.$http.post('/caixa/movimentacao');
      this.caixaInfo = caixaMovimentacaoData
    },
    async abrirCaixa() {
      this.setLoading(true);
      try {
        const { data } = await this.$http.post('/caixa/create', {
          id_tipo: 1,
          impressao_comprovante: this.caixaImpressaoComprovante,
          valor_abertura: this.caixaValorAbertura
        })
        if (this.caixaImpressaoComprovante) this.imprimir(data["comprovante-url"])
        this.isCaixaFechadoManual = false;

        const momentData = this.$moment(data.caixa.data.data_abertura.date);
        const dataFormatted = momentData.format('HH:mm [de] DD/MM/YY')
        this.caixaAberturaHorario = dataFormatted;
        try {
          await this.loadCaixaInfo()
        } catch (error) {
          console.log(error);
        }
        this.$toast.success('Caixa aberto com sucesso!')
      } catch (error) {
        console.log('Erro ao salvar o caixa');
      }
      this.setLoading(false);
    },
    async fecharCaixa() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Fechar o caixa",
          text: " Você deseja fechar o movimento do caixa?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.setLoading(true);
            try {
              const response = await this.$http.post('/caixa/create', {
                id_tipo: 2,
                impressao_comprovante: true,
              })
              this.imprimir(response.data["comprovante-url"]);
              this.resetCaixaInfo();
              this.caixaValorAbertura = null;
              this.$toast.success('Caixa fechado com sucesso!');
              this.isCaixaFechadoManual = true;
              this.$router.push({ name: "vendasLista" });
            } catch (error) {
              console.log(error);
            }
            this.setLoading(false);
            swal.stopLoading();
            swal.close();
            return;
          }
        });
    },
    resetCaixaInfo() {
      this.caixaInfo = {
        caixa: null,
        dados_abertura: {
          valor_abertura: 0,
        },
        pagamentos: [],
        pagamentos_usados: [],
        sangria: 0,
        suprimento: 0,
        total: 0,
        total_da_gaveta: 0,
        total_venda_na_gaveta: 0,
        total_venda_nao_gaveta: 0,
      }
    },
    async salvarSangriaSuprimento(operacao) {
      this.setLoading(true);
      try {
        const { data } = await this.$http.post('/caixa/create', {
          id_tipo: operacao === 'Sangria' ? 3 : 4,
          impressao_comprovante: this.sangria_suprimento_comprovante,
          valor_movimento: this.caixaValorOperacao,
          descricao: this.descricaoOperacao ?? null,
        })
        if (this.sangria_suprimento_comprovante) this.imprimir(data["comprovante-url"]);
        await this.loadCaixaInfo();
        this.caixaValorOperacao = null;
        this.descricaoOperacao = null;
        this.keyToRender = this.keyToRender + 1;
        this.$toast.success('Caixa atualizado com sucesso!')
      } catch (error) {
        console.log(error)
      }
      this.setLoading(false);
    },

    handleShowPagamentoModal({ transmitir, isSave }) {
      if (this.store.vendedorObrigatorio && !this.venda.vendedor) {
        this.$toast.warning("Obrigatório informar o vendedor!");
        return;
      }
      
      this.$bvModal.show('modal-forma-pagamento');
      this.transmitir = transmitir;
      this.pagamento = this.pagamentos.find(pagamento => pagamento.descricao === 'À vista' || pagamento.descricao === 'A vista');
      this.justSave = isSave;
    },
    requestFullScreen() {
      this.isFullScreen = true;
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    },
    closeFullscreen() {
      this.isFullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    resetCartaoInfo() {
      this.selectedAdmnistradora = null
      this.selectedBandeira = null
      this.codigoNsu = null
    },
    handleSaveCardInfo() {
      this.posStep = 1;
      this.handlePosPagamento();
    },
    onSearchAdmin(search, loading) {
      if (search.length) {
        loading(true);
        this.searchAdmin(loading, search, this);
      }
    },
    searchAdmin: _.debounce(async (loading, search, vm) => {
      const { data } = await vm.$http.post('/pessoa/get-administradoras', { nome: search });
      vm.administradoras = data
      loading(false);
    }, 350),
    async handlePosPagamento() {
      if (this.paymentCardListIndex === this.paymentsCartao.length) {
        if (this.solicitarDadosCartao) this.$bvModal.show('modal-pos-venda')
        this.posStep = 2;
        try {

          const dadosVenda = {
            ...this.venda,
            serie: this.serie,
          };

          await this.$http.post('/nfe/update', { dados: { ...dadosVenda, id: this.nota.id } });
          await this.transmitirNFCe(this.nota.id);

          this.posStep = 3;

          const printNFCe = await this.imprimirNFCe(this.nota.id);

          if (printNFCe["danfe-url"]) {
            this.imprimir(printNFCe["danfe-url"]);
          }

          if (printNFCe["voucher-url"]) {
            this.imprimir(printNFCe["voucher-url"]);
          }
          this.paymentsCartao = [];
        } catch (error) {
          console.log(error)
        }
        this.setLoading(false);
        this.isLoadingSubmit = false;
        this.notificationStore.lastPosNotification = {};
        this.paymentCardListIndex = 0;
        this.$bvModal.hide('modal-pos-venda');
        this.posStep = 0;
        this.novaVenda();
        return;
      };
      this.posStep = 1;

      const idCardPaymentToUpdate = this.paymentsCartao[this.paymentCardListIndex].id
      const indexPaymentModeCollectionCard = this.venda.paymentModeCollection.findIndex(payment => payment.id === idCardPaymentToUpdate && !payment?.administradora)

      if (!this.solicitarDadosCartao) {
        if (this.nota.id != this.notificationStore.lastPosNotification.message.id) {
          this.$toast.warning("Não foi possível atulizar a venda!");
          this.posStep = 6;
          return;
        }
        this.venda.paymentModeCollection[indexPaymentModeCollectionCard] = {
          ...this.venda.paymentModeCollection[indexPaymentModeCollectionCard],
          administradora: {
            cnpj_cpf: this.notificationStore.lastPosNotification.message.cnpj_cpf,
          },
          bandeira: this.notificationStore.lastPosNotification.message.bandeira,
          autorizacao: this.notificationStore.lastPosNotification.message.autorizacao,
        };
      } else {
        this.venda.paymentModeCollection[indexPaymentModeCollectionCard] = {
          ...this.venda.paymentModeCollection[indexPaymentModeCollectionCard],
          administradora: {
            cnpj_cpf: this.selectedAdmnistradora.cnpj_cpf,
          },
          bandeira: this.selectedBandeira.descricao,
          autorizacao: this.codigoNsu,
        };
        this.resetCartaoInfo();
      }
      this.paymentCardListIndex = this.paymentCardListIndex + 1;
      if (this.paymentCardListIndex < this.paymentsCartao.length) this.$bvModal.show('modal-pos-cartao');
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    addBuyer() {
      if (!this.newBuyer) {
        this.$toast.error('Preencha os campos obrigatórios')
        return;
      }
      this.valueCliente = { nome: this.newBuyer };
      this.valueCliente.cnpj_cpf = this.newBuyerCnpjCpf;
      this.venda.buyer.nome = this.newBuyer;
      this.venda.buyer.cnpj_cpf = this.newBuyerCnpjCpf;
      this.venda.observation = !this.nfe?.observation ? this.informacoesComplementares : this.nfe.observation;
      this.$bvModal.hide('modal-client-new');
      this.newBuyer = null;
      this.newBuyerCnpjCpf = null;
    },
    selectVencimento(index, date, pagamentoIndex) {
      this.indexVencimento = index
      this.vencimentoSelected = date
      this.selectedPagamento.pagamento = pagamentoIndex
    },
    changePagamentoEdited(valor) {
      this.pagamentoEditado = valor;
    },
    salvarVencimento() {
      this.listaPagamentos[this.selectedPagamento.pagamento].range[this.indexVencimento].date = this.vencimentoSelected;
      this.$bvModal.hide('modal-calendar')
    },
    salvarPagamento() {
      this.listaPagamentos[this.selectedPagamento.pagamento].range[this.selectedPagamento.parcelaIndex].value = this.pagamentoEditado;

      const indexEditPagamento = this.pagamentosEditados.findIndex(pagamento => pagamento?.index === this.selectedPagamento.parcelaIndex)
      if (indexEditPagamento === -1) {
        this.pagamentosEditados.push({
          index: this.selectedPagamento.parcelaIndex,
          value: this.pagamentoEditado
        })
      } else {
        this.pagamentosEditados.splice(indexEditPagamento, 1, {
          index: this.selectedPagamento.parcelaIndex,
          value: this.pagamentoEditado
        })
      };
      const pagamentosEditadosTotal = this.pagamentosEditados.reduce((acc, pagamento) => {
        if (pagamento?.value) return acc + pagamento.value
        else return acc
      }, 0)
      const editPagamentoCount = this.pagamentosEditados.length

      const pagamentoRestante = this.listaPagamentos[this.selectedPagamento.pagamento].value - pagamentosEditadosTotal;

      const valorParcelasRestantes = Math.round(pagamentoRestante / (this.listaPagamentos[this.selectedPagamento.pagamento].range.length - editPagamentoCount) * 100) / 100;
      this.listaPagamentos[this.selectedPagamento.pagamento].range = this.listaPagamentos[this.selectedPagamento.pagamento].range.map((item, index) => {
        const hasInPagamentosEditado = this.pagamentosEditados.find(pagamento => pagamento.index === index)
        return {
          ...item,
          value: hasInPagamentosEditado ?
            hasInPagamentosEditado.value
            :
            index !== this.selectedPagamento ?
              valorParcelasRestantes
              :
              this.pagamentoEditado
        }
      })

      this.$bvModal.hide('modal-pagamento')
    },
    substractQtd(index) {
      if (!this.store.productOfTradeCollection[index]) {
        return;
      }

      const price = this.store.productOfTradeCollection[index].product.preco;
      const quantity =
        parseFloat(this.store.productOfTradeCollection[index].quantity) -
        parseFloat(1);

      if (quantity <= 0) {
        return;
      }

      this.store.productOfTradeCollection[index].quantity = quantity;
      this.store.productOfTradeCollection[index].totalPrice =
        parseFloat(price) * quantity;
    },
    buscarProduto() {
      this.$refs.buscarProduto.$el.querySelector("input").focus();
    },
    setIsPercent() {
      // TODO
      if (this.tipoDescontoValue === 1) {
        this.tipoDescontoValue = 2;
      } else {
        this.tipoDescontoValue = 1;
      }
      if (this.tipoAcrescimoValue === 1) {
        this.tipoAcrescimoValue = 2
      } else {
        this.tipoAcrescimoValue = 1
      }
      this.isPercent = !this.isPercent;
    },
    setDesconto(value) {
      this.valueDiscount = value;
      this.saveDescontoAcrescimo();
    },
    setAcrescimo(value) {
      this.valueExtraValue = value;
      this.saveDescontoAcrescimo();
    },
    addDescontoAcrescimo() {
      if (this.valorTotal === 0) return;
      if (this.desconto === 0 && this.acrescimo === 0) {
        this.valorTotal = this.store.productOfTradeCollection.reduce(
          (total, item) => total + item.totalPrice,
          0
        );
        this.descontoAplicado = false;
        this.acrescimoAplicado = false;
      } else {
        if (this.desconto !== 0) {
          this.valorTotal = this.valorTotal - this.desconto
          this.descontoAplicado = true;
        } else {
          this.valorTotal = this.valorTotal + this.acrescimo
          this.acrescimoAplicado = true;
        }
      }
    },
    addQtd(index) {
      if (!this.store.productOfTradeCollection[index]) {
        return;
      }

      const price = this.store.productOfTradeCollection[index].product.preco;
      const quantity =
        parseFloat(this.store.productOfTradeCollection[index].quantity) +
        parseFloat(1);

      this.store.productOfTradeCollection[index].quantity = quantity;
      this.store.productOfTradeCollection[index].totalPrice =
        parseFloat(price) * quantity;
    },
    informarValor() {
      this.$nextTick(() => {
        if (this.$refs.valor) {
          this.$refs.valor.$el.querySelector("input").focus();
          let inputEl = this.$refs.valor.$el.querySelector("input");
          setTimeout(() => {
            inputEl.select();
          }, 100);
        }
      });
    },
    async novaVenda() {
      this.venda = new PdvData({});
      this.venda.buyer.cnpj_cpf = " ";
      this.produto = null;
      this.ultimoProduto = {};
      this.pagamento = this.pagamentos.find(pagamento => pagamento.descricao === 'À vista' || pagamento.descricao === 'A vista');
      this.cliente = {};
      if (this.$route.params.id) {
        this.id = this.$route.params.id;
        this.nota.id = parseInt(this.$route.params.id);
      } else this.nota = {}
      this.quantidade = 1 * (Math.pow(10, this.quantityOptions.precision));
      this.price = 1 * (Math.pow(10, this.priceOptions.precision));
      this.valorPgto = 0;
      this.store.productOfTradeCollection = [];
      this.importedSaleText = "";
      this.importationNumbers = [];
      this.importationObs = [];
      this.listaPagamentos = [];
      this.valueDiscount = 0;
      this.extraValue = 0;
      document.getElementById("desconto").value = "0,00";
      document.getElementById("acrescimo").value = "0,00";
      this.statusSelecionado = { id: 1, descricao: "Editando" };
      this.setInitialState();
    },
    async adicionarProduto(produtoKit) {
      if (!this.produto) return;
      const currentIndex = this.store.productOfTradeCollection.length;
      const quantidade = this.valorUS(this.quantidade);
      const price = this.roundTo2Digits(this.valorUS(this.price), this.priceOptions.precision);

      if (parseFloat(quantidade).toFixed(2) <= 0.0 && !produtoKit) {
        this.$toast.warning("Informe a quantidade do item!");
        this.$refs.quantidade.$el.querySelector("input").focus();
        return false;
      }

      if (parseFloat(price).toFixed(2) <= 0.0 && !produtoKit) {
        this.$toast.warning("Informe o valor do item!");
        return false;
      }

      if (this.store.verificaEstoque) {
        if (quantidade > parseFloat(this.produto.quantidade_original).toFixed(2)) {
          this.$toast.warning("Quantidade informada no item é superior a seu estoque!");
          return false;
        }
      }


      if (price < this.roundTo2Digits(this.valorUS(this.produto.originalPreco), this.priceOptions.precision)) {
        this.getRequestAdmin(() => {
          if (this.produto) this.produto.preco = price;
            let produto = !produtoKit ? new PdvVendaProduto({
              price: price,
              quantity: quantidade,
              totalPrice: this.roundTo2Digits(price * quantidade),
              product: this.produto,
              currentIndex: currentIndex + 1,
            }) : { ...produtoKit, };

            produto = {
              ...produto,
              ...(this.produto.variavel && { combinacao: this.produto.combinacao })
            }

            this.store.productOfTradeCollection.push(produto);
            const objDiv = document.getElementById("tableItensVenda");
            objDiv.scrollTop = objDiv.scrollHeight;

            this.ultimoProduto = this.produto;
            this.quantidade = 1 * (Math.pow(10, this.quantityOptions.precision));
            this.price = 1 * (Math.pow(10, this.priceOptions.precision));
            this.produto = null;
            this.produtos = [];

            return this.abrirVenda();
        });
      } else {
          if (this.produto) this.produto.preco = price;
          let produto = !produtoKit ? new PdvVendaProduto({
            price: price,
            quantity: quantidade,
            totalPrice: this.roundTo2Digits(price * quantidade),
            product: this.produto,
            currentIndex: currentIndex + 1,
          }) : { ...produtoKit, };

          produto = {
            ...produto,
            ...(this.produto.variavel && { combinacao: this.produto.combinacao })
          }

          this.store.productOfTradeCollection.push(produto);
          const objDiv = document.getElementById("tableItensVenda");
          objDiv.scrollTop = objDiv.scrollHeight;

          this.ultimoProduto = this.produto;
          this.quantidade = 1 * (Math.pow(10, this.quantityOptions.precision));
          this.price = 1 * (Math.pow(10, this.priceOptions.precision));
          this.produto = null;
          this.produtos = [];

          return this.abrirVenda();
      }
    },
    async setProduto(selectProduct, isBarCode = false) {
      if (selectProduct === null) return false;

      if (this.store.verificaEstoque) {
        if (parseFloat(selectProduct.quantidade).toFixed(2) <= 0) {
          this.$toast.warning("Produto selecionado está com estoque zerado!");
          return false;
        }
      }

      if (selectProduct?.variavel) {
        this.produto = selectProduct;
        this.$bvModal.show('variacaoModal');
        return;
      };

      if (selectProduct?.isKit) {
        selectProduct.productOfTradeCollection.forEach((product, index) => {
          const produtoToAdd = {
            ...product.product,
            product: { ...product.product },
            price: product.product.preco,
            quantity: product.product.quantidade,
            totalPrice: product.product.total,
            discount: product.product?.desconto ?? 0,
            active: true,
            currentIndex: index,
          };
          this.produto = produtoToAdd;
          this.adicionarProduto(produtoToAdd);
        });
        return;
      }

      const preco_atacado = selectProduct?.preco_atacado ?? 0;
      const qtd_atacado = selectProduct?.quantidade_atacado ?? 0;
      const isAtacadoValue = this.quantidade >= qtd_atacado && preco_atacado > 0
      if (isAtacadoValue) {
        this.price = this.numberToReal(parseFloat(preco_atacado), 'preco');
      } else this.price = this.numberToReal(parseFloat(selectProduct.preco), 'preco');

      this.produto = {
        ...selectProduct,
        preco: this.price,
        quantidade: this.quantidade,
        tipo_atacado: isAtacadoValue,
      };

      const total = this.quantidade * parseFloat(this.price);

      this.produto.originalPreco = this.produto.preco;
      this.produto.quantidade_original = selectProduct.quantidade;
      this.produto.total = total;

      this.ultimoProduto = this.produto;

      this.$nextTick(() => {
        if (this.$refs.quantidade) {
          this.$refs.quantidade.$el.querySelector("input").focus();
          if (!isBarCode) {
            let inputEl = this.$refs.quantidade.$el.querySelector("input");
            setTimeout(() => {
              inputEl.select();
            }, 100);
          }
          if (isBarCode) {
            this.adicionarProduto();
          }
        }
      });

      return true;
    },
    abrirVenda() {
      this.$nextTick(() => {
        if (this.$refs.buscarProduto) {
          this.$refs.buscarProduto.$el.querySelector("input").focus();
        }
      });
    },
    onSearch(search, loading) {
      if (search.length > 1 && search[0] === '+') {
        loading(true);
        this.searchKit(loading, search, this);
        return;
      }
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        this.search(loading, search, this);
      }
    },
    search: _.debounce(async (loading, search, vm) => {
      if (search === "" || search === null) {
        return loading(false);
      }

      const $this = vm;

      let busca = search;
      if (busca.split("*").length > 1) {
        let quantidade = 1 * 10 * $this.quantityOptions.precision;
        [quantidade, busca] = busca.split("*");
        $this.quantidade = $this.numberToReal(parseFloat(quantidade), 'quantidade');
      }

      if (busca.split("-").length > 1) {
        let indiceProduto;
        let funcaoDelete = null;
        [funcaoDelete] = busca.split("-");

        if (funcaoDelete === "") {
          [, indiceProduto] = busca.split("-");
          if (indiceProduto % 1 === 0 && indiceProduto !== "") {
            return $this.cancelaProduto(indiceProduto);
          }
        }
      }
      if (busca === "") {
        return false;
      }

      loading(true);

      $this.produtos = await [];
      const { data } = await $this.$http.post("/produtos/filter-item", {
        filter: busca,
        typeFilter: $this.typeFilter
      });
      if (data.length === 1) {
        if ($this.isBarCode(busca) && Number.parseInt(busca)) {
          $this.setProduto(data[0], true);
        }
      }

      $this.produtos = data;

      loading(false);
      return true;
    }, 350),
    searchKit: _.debounce(async (loading, search, vm) => {
      const { data } = await vm.$http.post('/kit/get-kit', { search });
      vm.produtos = data.map(kit => ({
        ...kit,
        descricao: kit.nome,
        isKit: true,
      }))
      loading(false);
    }, 350),
    getKitInfo(kitData) {
      const description = kitData.productOfTradeCollection
        .reduce((acc, produto, index) => acc + `${index + 1} - ${produto.product.descricao} - QTD: ${produto.product.quantidade}<br/>`, '')
      return description;
    },
    isBarCode(codigo) {
      if (codigo.length === 8) {
        return true;
      }

      if (codigo.length === 12) {
        return true;
      }

      if (codigo.length === 13) {
        return true;
      }

      if (codigo.length === 14) {
        return true;
      }

      return false;
    },
    cancelaProduto(indiceProduto) {
      let produto = indiceProduto - 1;
      if (indiceProduto === null) {
        if (this.store.productOfTradeCollection.length === 0) {
          return false;
        }
        produto = this.store.productOfTradeCollection.length - 1;
      }

      const item = this.store.productOfTradeCollection[produto].product.descricao;

      this.$swal
        .fire({
          icon: "warning",
          title: "Cancelar o item " + item + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.store.productOfTradeCollection[produto].active = false;
            this.store.productOfTradeCollection[produto].totalPrice = 0;
            this.store.productOfTradeCollection[produto].quantity = 0;
            this.$toast.success("Item cancelado com sucesso!");
          }
        });
    },
    async defaultPagamento() {
      const pagamentoAVista = this.pagamentos.filter((item) => {
        const itemFilter = item.descricao
          ? item.descricao.toUpperCase()
          : "".toUpperCase();
        const newText = String("À Vista").toUpperCase();
        return itemFilter.indexOf(newText) > -1;
      });

      const pagamento = {
        ...pagamentoAVista[0],
        value: this.total,
        meioPagamento: {
          description: "À Vista",
          id: "1",
          name: "À Vista",
        },
      };
      pagamento.range = [{
        value: this.total,
        date: this.$moment().format("YYYY-MM-DD"),
      }]
      this.pagamentosEditados = [];
      this.venda.paymentModeCollection.push(pagamento);
    },
    async finalizarVenda(transmitirVenda) {
      const hasCartao = this.venda.paymentModeCollection.find(payment => payment.descricao.includes('A prazo'))
      if (this.venda.buyer.cnpj_cpf) {
        this.venda.buyer.cnpj_cpf = this.venda.buyer.cnpj_cpf.replace(
          /[^\d]+/g,
          ""
        );
      } else if (transmitirVenda && hasCartao) {
        this.$toast.error('CNPJ obrigatorio para transmitir NFC-e');
        this.setLoading(false);
        this.closeInfoVendaModal();
        return;
      }

      if (this.store.pafNFCe && !transmitirVenda) return;

      if (this.total === 0) {
        return false;
      }
      this.venda.paymentModeCollection = this.listaPagamentos;

      if (this.venda.paymentModeCollection.length === 0) {
        await this.defaultPagamento();
      }

      try {
        const troco = this.roundTo2Digits(this.valorPago - this.total);
        this.venda.remainder = troco > 0 ? troco : 0;
        this.venda.price = this.total;
        this.venda.isNFce = transmitirVenda;
        if (!this.venda.isNFce) {
          this.venda.status = 7;
          this.venda.statusSelecionado = { id: 7, descricao: "Finalizado" };
        }

        this.venda.productOfTradeCollection =
          this.store.productOfTradeCollection.filter((item) => {
            const itemFilter = item.active.toString();
            return itemFilter.indexOf("true") > -1;
          });

        const dados = {
          ...this.venda,
          serie: this.serie,
        };


        if (!this.nota.id) {
          this.nota = await this.criarNota(dados);
        } else await this.$http.post('/nfe/update', {
          dados: {
            ...dados,
            id: this.nota.id,
            ...(this.pedidos.length > 0 && { pedidos: this.pedidos })
          }
        });
        this.pedidos = [];

        if (!this.nota.id) {
          this.setLoading(false);
          return this.$toast.error("Ocorreu um erro ao salvar!");
        }

        let printVenda = {};
        if (!transmitirVenda) {
          if (!this.store.naoGeraImpressao)   {
            this.posStep = 3;
            printVenda = await this.imprimirVenda(this.nota.id);
          }
        }

        if (transmitirVenda) {
          this.venda.paymentModeCollection
            .filter(payment => {

              if (payment.id_meio_pagamento == 3) {
                this.paymentsCartao.push(payment);
              }
              
              if (payment.id_meio_pagamento == 4) {
                this.paymentsCartao.push(payment);
              }
            });
          
          if (this.paymentsCartao.length > 0 && this.solicitarDadosCartao) {
            const { data } = await this.$http.post('/nfe/bandeiras')
            this.bandeiras = data;
            this.posStep = 0;
            this.$bvModal.show('modal-pos-cartao');
            this.setLoading(false);
            return;
          }

          if (this.paymentsCartao.length > 0 && this.integracaoPos) {
            this.posStep = 1;
            this.$bvModal.show('modal-pos-venda');
            this.isLoadingPosData = true;
            this.setLoading(false);
            return;
          }

          await this.transmitirNFCe(this.nota.id);
          this.posStep = 3;
          const printNFCe = await this.imprimirNFCe(this.nota.id);

    
          if (printNFCe["danfe-url"]) {
            this.imprimir(printNFCe["danfe-url"]);
          }

          if (printNFCe["voucher-url"]) {
            this.imprimir(printNFCe["voucher-url"]);
          }

          if (printNFCe["duplicata"]) {
            this.imprimir(printNFCe["duplicata"]);
          }
        }


        if (printVenda["venda-url"] && !transmitirVenda) {
          this.imprimir(printVenda["venda-url"]);
        }

        if (printVenda["voucher-url"] && !transmitirVenda) {
          this.imprimir(printVenda["voucher-url"]);
        }

        if (printVenda["duplicata"] && !transmitirVenda) {
          this.imprimir(printVenda["duplicata"]);
        }

        this.abrirVenda();
        if (this.$route.params.id) {
          this.$router.push({ name: 'vendasNovo' })
        } else this.novaVenda();

        await this.loadCaixaInfo();

        this.setLoading(false);
        this.closeInfoVendaModal();
        this.isLoadingSubmit = false;
        this.venda.observation = !this.nfe?.observation ? this.informacoesComplementares : this.nfe.observation
        this.valorPagoVenda = 0;
      } catch (e) {
        this.setLoading(false);
        this.currentErrorMessage = e?.response?.data?.error_message ?? e.msg;
        this.isLoadingSubmit = false;
        this.nota = {};
        this.closeInfoVendaModal();
        return;
      }
    },

    changeDescontoAcrescimo(params) {
      if (params.discount.percentDiscount !== 0) {
        this.setInternalDiscountValue(params.discount.percentDiscount);
      }
      if (params.discount.percentDiscount === 0 && params.discount.valueDiscount === 0) {
        this.venda.discount = 0;
      }
      if (params.extraValue.percentExtraValue === 0 && params.extraValue.valueExtraValue === 0) {
        this.venda.extraValue = 0;
      }

      if (params.extraValue.percentExtraValue !== 0) {
        this.setInternalExtraValue(params.extraValue.percentExtraValue);
      }

      if (params.discount.valueDiscount !== 0) {
        this.venda.discount = params.discount.valueDiscount;
        this.venda.isPercent = this.isPercent;
        this.venda.tipoDescontoValue = this.tipoDescontoValue;
      }
      if (params.extraValue.valueExtraValue !== 0) {
        this.venda.extraValue = params.extraValue.valueExtraValue;
        this.venda.tipoAcrescimo = this.tipoAcrescimo;
        this.venda.tipoAcrescimoValue = 1;
      }
    },

    setInternalExtraValue(percentExtraValue) {
      if (parseFloat(percentExtraValue) > 0) {
        const total = this.store.productOfTradeCollection.reduce((total, item) => total + item.totalPrice, 0);
        const valueExtraValue = parseFloat(
          total * percentExtraValue
        ).toFixed(2);
        this.venda.extraValue = this.valorReal(valueExtraValue);
        this.venda.tipoAcrescimo = {
          value: 1,
          text: "Valor R$",
        };
        this.venda.tipoAcrescimoValue = 1;
      }
    },
    setInternalDiscountValue(percentDiscount) {
      if (percentDiscount > 0) {
        const total = this.store.productOfTradeCollection.reduce((total, item) => total + item.totalPrice, 0);
        const valueDiscount = (total * percentDiscount).toFixed(2);
        this.venda.discount = this.valorReal(valueDiscount);
        this.venda.isPercent = {
          value: 1,
          text: "Valor R$",
        };
        this.venda.tipoDescontoValue = 1;
      }
    },

    async criarNota(dados) {
      if (this.id) {
        dados.id = this.id;
      }

      const { data } = await this.$http.post(this.url, {
        dados: {
          ...dados,
          ...(this.pedidos.length > 0 && { pedidos: this.pedidos })
        },
      });

      return data;
    },

    async imprimirVenda(id) {
      const { data } = await this.$http.post("/nfe/printVenda", {
        id,
      });

      return data;
    },

    async imprimirNFCe(id) {
      const { data } = await this.$http.post("/nfe/printNota", {
        id,
      });

      return data;
    },

    async transmitirNFCe(id) {
      const { data } = await this.$http.post("/nfe/transmitter", {
        id,
      });

      return data;
    },
    imprimir(url) {
      const printDafe = window.open(url, "_blank");
      if (!printDafe || printDafe.closed) {
        return this.$toast.error(
          "Habilite o popup do navegador para visualizar os relatórios/comprovantes"
        );
      }
      printDafe.focus();

      return setTimeout(() => {
        printDafe.print();
      }, 1000);
    },
    getRangePayment(payment) {
      return payment.reduce((total, item) => total + item.value, 0);
    },
    changeCliente(selectedCliente = null) {
      if (selectedCliente !== null) {
        this.venda.buyer = selectedCliente;
      }
    },
    setCliente(cliente) {
      if (!cliente) {
        this.selectedCliente = null;
        return
      };

      if (parseFloat(cliente.limite_credito) > 0) {
        if (
          cliente.limite_de_credito &&
          parseFloat(cliente.limite_credito) <
          parseFloat(cliente.limite_de_credito.valor_total)
        ) {
          this.$toast.warning(
            "Limite de crédito do cliente atingido, verifique!"
          );
          this.$bvModal.hide('modal-client');
          this.getRequestAdmin(() => {
            this.setClienteInadimplente(cliente);
          });
          return;
        }
      }

      this.selectedCliente = cliente;
    },

    setClienteInadimplente(cliente) {
      this.limiteCreditoAtigindo = true;
      this.valorLimiteCredito = cliente.limite_credito;
      this.valorLimiteCreditoAtingido = cliente.limite_de_credito.valor_total;
      this.selectedCliente = cliente;
      this.valueCliente = { ...this.selectedCliente };
      this.venda.buyer = this.cliente;
      this.selectedCliente = {
        cnpj_cpf: null
      };
    },
    saveCliente() {
      if (!this.selectedCliente) return this.$toast.error('Selecione o cliente para salvar!')
      this.valueCliente = { ...this.selectedCliente };
      this.venda.buyer = this.valueCliente;
      this.$toast.success("Adicionado sucesso!");
      this.$bvModal.hide("modal-client");
      this.selectedCliente = {
        cnpj_cpf: null
      };
      if (this.fromPagamentoModal) {
        this.handleFinalizar(this.isNfce)
      }
    },
    onSearchCliente(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchClient(loading, search, this);
      }
    },
    searchClient: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 500),

    changePagamentos(selectedPagamento) {
      this.pagamento = selectedPagamento;
      this.$nextTick(() => {
        this.$refs.valorPagamento.$el.querySelector("input").focus();
      });
      this.pagamentosEditados = [];
    },
    changeStatus(selectedStatus) {
      this.statusSelecionado = selectedStatus;
    },
    async adicionarPagamento() {
      const valor = await this.valorUS(this.valorPgto);

      let pagamento = {
        value: parseFloat(valor),
        ...this.pagamento,
      };
      if (valor > 0 && pagamento.descricao) {
        let range = this.calculateInstallments(
          pagamento,
          parseFloat(pagamento.value),
        );

        const descricaoPagamento = pagamento.descricao.toLowerCase();
        if (descricaoPagamento === 'à vista' || descricaoPagamento === 'cartão de débito 1x' || descricaoPagamento === 'pix') {
          range[0] = {
            ...range[0],
            date: this.thisDate()
          }
        };

        pagamento = {
          ...pagamento,
          range,
        };

        if (pagamento.value > this.total && this.total !== 0) {
          pagamento = {
            ...pagamento,
            value: this.total,
          };
        }

        this.listaPagamentos.push(pagamento);

        if (descricaoPagamento !== 'Nenhum') this.checkValorPago(pagamento.value);

        this.parcela = null;
        this.operacao = null;
        this.valueMeioPagamento = null;
        this.pagamento = null;
        this.changePagamentos({});
        this.pagamentoEditado = this.listaPagamentos[this.selectedPagamento.pagamento].range[0]?.value
        this.$toast.success("Adicionado com sucesso!");
        this.$refs.finishModal.focus();
      }
    },
    removePagamento(indice) {
      const [deletedPagamento] = this.listaPagamentos.splice(indice, 1);
      this.checkValorPago((deletedPagamento.descricao === 'Nenhum' ? deletedPagamento.value : 0));
      this.$toast.success("Removido com sucesso!");
    },
    checkValorPago(valor = 0) {
      if (this.valorPagoVenda === 0 && valor === 0) {
        this.valorPgto = this.valorReal(this.total);
        return;
      }
      const valorPagar = this.total - (this.valorPagoVenda + valor);
      
      this.valorPgto = valorPagar < 0 ? "0,00" : this.valorReal(valorPagar);

      this.valorPgaoVenda += valor;
    },
    calcValue(percent) {
      const valor = parseFloat(percent / 100);
      return valor;
    },
    setInitialState() {
      this.valueDiscount = 0;
      this.percentDiscount = 0;
      this.valueExtraValue = 0;
      this.percentExtraValue = 0;
      this.tipoDescontoValue = 1;
      this.tipoAcrescimoValue = 1;
      this.valueCliente = {};
      this.limiteCreditoAtigindo = false;
      this.valorLimiteCredito = 0;
      this.valorLimiteCreditoAtingido = 0;
    },
    // setIsPercent(tipo) {
    //   if (tipo) {
    //     this.tipoDescontoValue = tipo.value;
    //   }
    // },
    setTipoAcrecimo(tipo) {
      if (tipo) {
        this.tipoAcrescimoValue = tipo.value;
      }
    },
    async saveDescontoAcrescimo() {
      if (this.venda.discount !== 0 && this.venda.discount === this.valueDiscount) return;
      if (this.venda.extraValue !== 0 && this.venda.extraValue === this.valueExtraValue) return;
      let discount;
      let extraValue;

      this.descontoAplicado = this.valueDiscount !== 0
      this.acrescimoAplicado = this.valueExtraValue !== 0

      if (this.tipoDescontoValue === 2 && this.valueDiscount !== 0) {
        discount = {
          valueDiscount: 0,
          percentDiscount: this.calcValue(this.valueDiscount),
        };
      } else {
        discount = {
          valueDiscount: this.valueDiscount,
          percentDiscount: 0,
        };
      }
      extraValue = {
        valueExtraValue: this.valueExtraValue,
        percentExtraValue: 0,
      };
      let params = {
        discount,
        extraValue,
      };

      return this.changeDescontoAcrescimo(params);
    },
    handleSairDaVenda() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Cancelar a venda em andamento?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "vendasLista" });
          }
        });
    },
    handleFinalizar(finalizarVenda) {
      if (this.store.pafNFCe && !finalizarVenda) return;
      if (this.posStep === 5) return;
      this.isNfce = finalizarVenda;
      this.posStep = 5;
      if (this.roundTo2Digits(this.valorPago) > 0) {
        if (
          this.roundTo2Digits(this.valorPago) <
          this.roundTo2Digits(this.total)
        ) {
          this.closeInfoVendaModal();
          return this.$toast.warning(
            "Valor pago deve ser igual ao total da venda!"
          );
        }
      }
      if (this.listaPagamentos.length === 0) {
        this.closeInfoVendaModal();
        this.$toast.warning('Necessário incluir pagamento!')
        return
      }
      const hasAprazoPagamento = !!this.listaPagamentos.find(element => element?.informar_cliente)
      if (this.listaPagamentos.length > 0 && hasAprazoPagamento) {
        if (JSON.stringify(this.valueCliente) === '{}' || !this.valueCliente || this.valueCliente.length === 0) {
          this.$toast.warning('Necessário incluir cliente!')
          this.$bvModal.hide('modal-forma-pagamento');
          this.$bvModal.show('modal-client');
          this.closeInfoVendaModal();
          return
        }
      }
      this.$bvModal.show('modal-pos-venda');
      this.$bvModal.hide('modal-forma-pagamento');
      this.finalizarVenda(finalizarVenda);
    },

    async handleSalvarVenda(transmitirVenda) {
      this.posStep = 5;
      this.$bvModal.hide('modal-forma-pagamento');
      this.$bvModal.show('modal-pos-venda');
      if (this.roundTo2Digits(this.valorPago) > 0) {
        if (
          this.roundTo2Digits(this.valorPago) <
          this.roundTo2Digits(this.total)
        ) {
          this.closeInfoVendaModal();
          return this.$toast.warning(
            "Valor pago deve ser igual ao total da venda!"
          );
        }
      }
      if (this.total === 0) {
        return false;
      }

      this.venda.paymentModeCollection = this.listaPagamentos;

      if (this.venda.paymentModeCollection.length === 0) {
        await this.defaultPagamento();
      }

      try {
        const troco = this.roundTo2Digits(this.valorPago - this.total);
        this.venda.remainder = troco > 0 ? troco : 0;
        this.venda.price = this.total;
        this.venda.isNFce = transmitirVenda;
        if (!this.venda.isNFce) {
          this.venda.status = this.statusSelecionado.id;
          this.venda.statusSelecionado = this.statusSelecionado;
        }

        this.venda.productOfTradeCollection =
          await this.store.productOfTradeCollection.filter((item) => {
            const itemFilter = item.active.toString();
            return itemFilter.indexOf("true") > -1;
          });

        const dados = {
          ...this.venda,
          serie: this.serie,
        };
        if (dados.buyer.cnpj_cpf) {
          dados.buyer.cnpj_cpf = dados.buyer.cnpj_cpf.replace(
            /[^\d]+/g,
            ""
          );
        }

        if (!this.nota.id) {
          this.nota = await this.criarNota(dados);
        } else await this.$http.post('/nfe/update',
          {
            dados: {
              ...dados,
              id: this.nota.id,
              ...(this.pedidos.length > 0 && { pedidos: this.pedidos })
            }
          });
        this.pedidos = [];

        this.setLoading(false);
        this.isLoadingSubmit = false;
        this.$router.push({ name: "vendasLista" });
      } catch (e) {
        this.setLoading(false);
        this.currentErrorMessage = e?.response?.data?.error_message ?? e.msg;
        this.isLoadingSubmit = false;
        return this.$toast.error(this.currentErrorMessage);
      }
      this.closeInfoVendaModal();
    },
    closeInfoVendaModal() {
      this.$bvModal.hide('modal-pos-venda')
      this.posStep = 0;
    },
    removeCliente() {
      this.venda.buyer = {};
      this.valueCliente = {};
      this.$toast.success("Cliente removido com sucesso!");
      this.limiteCreditoAtigindo = false;
      this.valorLimiteCredito = 0;
      this.valorLimiteCreditoAtingido = 0;
    },
    setStatusVenda(status) {
      if (status) {
        this.statusVenda = status;
      }
    },
    onSearchVendedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchVendedor(loading, search, this);
      }
    },
    searchVendedor: _.debounce(async (loading, search, vm) => {
      vm.vendedores = await vm.getVendedorByNome(search);
      if (loading) loading(false);
    }, 500),
    async getIntegracaoPos() {
      try {
        const { data } = await this.$http.post("/pos/get");
        if (data.length > 0) {
          this.integracaoPos = true;
          this.equipamentosPos = data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async solicitarPagamentoPos() {
      this.posStep = 6;
      this.isLoadingPosData = false;
      this.solicitadoPagamentoPos = true;

      await this.$http.post("/pos/solicitar-pagamento", {
        'equipamento': this.equipamentosSelecionado,
        'pagamentos': this.listaPagamentos,
        'nfce': this.nota
      });
    },
    setEquipamento(equipamento) {
      this.equipamentosSelecionado = equipamento;
      this.solicitadoPagamentoPos = true;
    },
    async cancelarPagamentoPos() {
      this.posStep = 1;
      this.isLoadingPosData = true;
      this.solicitadoPagamentoPos = true;

     const data =  await this.$http.post("/pos/cancelar-pagamento", {
        'equipamento': this.equipamentosSelecionado,
        'pagamentos': this.listaPagamentos,
        'nfce': this.nota
      });

      if (data.data.responseCode == 200) {
        this.$toast.success('Cancelamento efetuado com sucesso, atualize a lista de pagamentos no APP!');
        this.solicitadoPagamentoPos = false;
        this.solicitarDadosCartao = false;
        this.paymentsCartao = [];
      }
    },
    async gerarPAFEstoqueTotal() {
      this.addLoadingToButton("gerarPAFEstoqueTotal");
      try {
        const result = await this.$http.post("/paf/estoque-total");
        if (result["data"]["file_url"]) {
          window.open(result["data"]["file_url"], "_blank");
        }
        this.$toast.success("Arquivo gerado com sucesso!");
        this.$bvModal.hide("estoqueTotal");
      } catch (error) {
        console.log(error);
        this.removeLoadingToButton("gerarPAFEstoqueTotal");
      }
      this.removeLoadingToButton("gerarPAFEstoqueTotal");
    },
  },
  computed: {
    total() {
      const produtos = this.store.productOfTradeCollection;
      let valor = produtos.reduce((total, item) => total + item.totalPrice, 0);
      if (this.venda.discount) {
        valor -= this.venda.discount;
      }
      if (this.venda.extraValue) {
        valor += parseFloat(this.venda.extraValue);
      }
      return valor;
    },
    modalTitle() {
      return this.posStep < 1 ? 'Consultando POS' : `Finalizando a ${this.isNfce ? 'NFce' : 'Pré-venda'}`
    },
    valorPrimeiraParcela() {
      if (this.listaPagamentos[this.selectedPagamento.pagamento] === undefined) return 0;
      return this.listaPagamentos[this.selectedPagamento.pagamento].range[0].value ?? 0;
    },
    troco() {
      return this.valorUS(this.valorPgto) - parseFloat(this.total)
    },
    valorPago() {
      if (this.listaPagamentos.length === 0 || !this.listaPagamentos) {
        return 0;
      }

      return this.listaPagamentos.reduce(
        (total, item) => total + this.getRangePayment(item.range),
        0
      );
    },
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "itsblocked",
    ]),
    ...mapState([
      "loading",
      "currentUser",
    ]),
    isLoading() {
      return this.loading.loading;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    toolbarDisplay() {
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
  },
};
</script>

<style>
@media (max-width: 767px) {
  .right-card {
    /* Mobile styles */
    padding-top: 1rem;
    margin-top: 1.5rem;
  }
}

.busca-produto .vs__search {
  font-weight: 700;
  font-size: 1.45rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
}

.busca-produto .vs__dropdown-toggle {
  border: 0px !important;
}

.busca-produto .vs__actions {
  display: none;
}

.busca-produto .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-produto .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.busca-cliente .vs__search {
  font-size: 18px;
}

.busca-cliente .vs__dropdown-toggle {
  color: #ee7361;
  background-color: #fff;
  border: 2px solid #dedede;
  height: 40px;
}

.busca-cliente .vs--open .vs__dropdown-toggle {
  background-color: #fff;
  border: 2px solid #ee7361;
}

.busca-cliente .vs__actions {
  display: none;
}

.busca-cliente .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus {
  background-color: #ee73615e !important;
  color: #ee7361 !important;
}

.busca-cliente .vs__dropdown-menu {
  color: #ee7361 !important;
}

.busca-cliente .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-cliente .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pdv-form {
  top: -30px;
}

.remove-pgto {
  font-weight: bold;
  font-size: 18px;
  color: #4f4f4f;
}

.add-pgto {
  font-weight: bold;
  font-size: 22px;
  color: #ee7361;
  float: right;
}

.input-quantidade {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: transparent;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: right;
}

.input-quantidade:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  border: 0px;
  background-color: transparent;
  color: #fff;
}

.card {
  border: 0;
}

h4 {
  color: #6d747c;
  /* font-weight: 900; */
}

.pdv-card-logo {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100vh;
  background-color: #fff;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  max-height: calc(100vh - 375px);
  height: calc(100vh - 375px);
  border: 0px;
  top: -20px !important;
  text-align: center !important;
  position: relative;
}

.pdv-card-logo img {
  max-width: 100%;
  height: auto;
}

.pdv-acoes {
  position: absolute;
  width: 100%;
  margin-top: -32px;
}

.pdv-atalhos {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.pdv-atalhos .btn-success {
  background-color: #eee5ff;
  border-color: #ee7361;
}

.pdv-atalhos button {
  margin-right: 0px !important;
  background-color: #fff;
  color: #4f4f4f;
  font-size: 14px;
  margin-top: 8px;
  height: 60px;
}

.pdv-atalhos button:hover {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.btn-finalizar-venda {
  background-color: #eee5ff !important;
  font-size: 1.25rem !important;
  text-align: center !important;
  cursor: pointer;
}

.card-total-right p,
.card-total-left p {
  color: #fff !important;
}

/* @media (max-width: 1024px) { */
.card-header h5,
.card-header h3 {
  margin-bottom: 0rem;
}

.card-header .form-control {
  height: auto;
  margin-top: 0px;
}

.card-header .busca-produto {
  margin-top: 2px;
}

.card-titulo-itens .card-body {
  padding: 5px 0 !important;
}

.card-itens .table tr th {
  padding: 5px;
  font-size: 12px;
}

.card-itens .table tr td,
.card-total-right p,
.card-total-left p {
  font-size: 12px;
}

.tableItensVenda {
  max-height: 300px !important;
  height: calc(100vh - 395px) !important;
}

.pdv-acoes {
  margin-top: -32px;
}

.pdv-card-logo {
  max-height: calc(100vh - 345px) !important;
  height: calc(100vh - 345px) !important;
}

.atalhos li a,
.atalhos li button span {
  font-size: 12px !important;
}

.card-total-left,
.card-total-right {
  height: 80px !important;
}

.btn.btn-secondary {
  color: #ee7361;
  background-color: #fff;
  border-color: #fff;
}

.venda-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  padding: 1rem;
  width: fit-content;
  border-radius: 8px;
  margin-top: 3.5rem;
  margin: 3.5rem auto 0;
  background-color: white;
  z-index: 999;
}

</style>
