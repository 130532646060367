<template>
  <div class="container-fluid">
    <div class="card-body h-100" v-if="!firstLoad">
      <b-card>
        <b-tabs pills vertical nav-wrapper-class="w-25">
          <b-tab title="Importar XML" :active="store.currentTab === 0" :disabled="store.currentTab !== 0"
            v-if="!importacaoManual">
            <b-card-text>Selecione o arquivo XML para a importação</b-card-text>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <b-form-file ref="xmlfile" accept=".xml" placeholder="Selecione o arquivo xml.."
                    :class="{ hasXmlErrors: xmlError, inputFilled: xmlFilled }" @change="setXMLFile"></b-form-file>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <button style="margin-right: 5px" type="button" ref="importar_xml" class="btn btn-light-warning"
                    @click="importXML()">
                    Importar XML
                  </button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Informações fiscais" :active="store.currentTab === 1" :disabled="store.currentTab !== 1">
            <b-card-text>Informações fiscais</b-card-text>
            <b-row>
              <b-col md="7">
                <b-form-group label="Natureza" label-for="natureza">
                  <VueSelect ref="buscarNatureza" :filterable="false" @input="setNatureza" :options="naturezas"
                    :class="{ hasErrors: naturezaError }" @search="onSearchCFOP" v-model="natureza">
                    <template slot="no-options">
                      Informe o código da Natureza
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="CFOP" label-for="cfop">
                  <b-form-input disabled id="cfop" name="cfop" v-model="cfop">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="numeroNFe" label-for="numeroNFe" label="Número">
                  <b-form-input id="numeroNFe" name="numeroNFe" v-model="$v.form.numeroNFe.$model"
                    :state="validateState('numeroNFe')">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="7">
                <b-form-group id="chaveDeAcesso" label-for="chaveDeAcesso" label="Chave">
                  <b-form-input id="chaveDeAcesso" name="chaveDeAcesso" v-model="$v.form.chaveDeAcesso.$model"
                    :state="validateState('chaveDeAcesso')">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="serieNFe" label-for="serieNFe" label="Série">
                  <b-form-input id="serieNFe" name="serieNFe" v-model="$v.form.serieNFe.$model"
                    :state="validateState('serieNFe')">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="modeloNFe" label-for="modeloNFe" label="Modelo">
                  <b-form-input v-model="$v.form.modeloNFe.$model" :state="validateState('modeloNFe')">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group id="dataEmissao" label-for="dataEmissao" label="Data Emissão">
                  <b-form-datepicker v-model="form.dataEmissao" :state="validateState('dataEmissao')" locale="pt-br"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }">
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="dataEntrada" label-for="dataEntrada" label="Data Entrada">
                  <b-form-datepicker v-model="$v.form.dataEntrada.$model" :state="validateState('dataEntrada')"
                    locale="pt-br" :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }">
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Fornecedor" :active="store.currentTab === 2" :disabled="store.currentTab !== 2">
            <b-card-text>Fornecedor</b-card-text>
            <b-card-text>Para continuar, você deve preencher os dados referente ao
              Fornecedor.</b-card-text>
            <b-row>
              <b-col md="6">
                <b-form-group label="Pesquisar Fornecedor" label-for="fornecedor">
                  <VueSelect ref="buscarFornecedor" :filterable="false" v-model="fornecedor" @input="setFornecedor"
                    :options="fornecedores" @search="onSearchFornecedor">
                    <template slot="no-options">
                      Informe o nome do Fornecedor
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="cnpj_cpf" label-for="cnpj_cpf" label="CNPJ">
                  <b-form-input disabled id="cnpj_cpf" name="cnpj_cpf" v-model="formFornecedor.cnpj_cpf"
                    v-mask="['###.###.###-##', '##.###.###/####-##']">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="ie_rg" label-for="ie_rg" label="IE">
                  <b-form-input disabled id="ie_rg" name="ie_rg" v-model="formFornecedor.ie_rg">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group id="cep" label-for="cep" label="CEP">
                  <b-form-input disabled id="cep" name="cep" v-model="formFornecedor.cep" v-mask="['#####-###']">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group id="logradouro" label-for="logradouro" label="Endereço">
                  <b-form-input disabled id="logradouro" name="logradouro" v-model="formFornecedor.logradouro">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="bairro" label-for="bairro" label="Bairro/Distrito">
                  <b-form-input disabled id="bairro" name="bairro" v-model="formFornecedor.bairro">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="5">
                <b-form-group id="municipio" label-for="Cidade" label="Cidade">
                  <b-form-input disabled id="cidade" name="cidade" v-model="formFornecedor.cidade"
                    aria-describedby="cidade">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="uf" label-for="uf" label="UF">
                  <b-form-input disabled id="uf" name="uf" v-model="formFornecedor.uf">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="telefone" label="Telefone">
                  <b-form-input disabled v-mask="['(##) #### ####', '(##) # #### ####']" v-model="formFornecedor.fone">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <button class="btn btn-primary font-weight-bold" @click="openNewFornecedor">
                  Adicionar novo Fornecedor
                </button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Itens" :active="store.currentTab === 3" :disabled="store.currentTab !== 3"
            v-if="!importacaoManual">
            <b-card-text>Para continuar, você deve relacionar os produtos encontrados na
              nota fiscal, à um produto cadastrado no Nuts. Caso não vincular, o
              mesmo será cadastrado automaticamente.</b-card-text>
            <ItemsCompora :total="total" :items="productOfTradeCollection" @onItemsChange="(items) => productOfTradeCollection = [...items]"/>
          </b-tab>

          <b-tab title="Quantidade" :active="store.currentTab === 4" :disabled="store.currentTab !== 4"
            v-if="!importacaoManual">
            <b-card-text>Informe a unidade medida dos itens abaixo</b-card-text>
            <QuantidadesCompra :total="total" :items="productOfTradeCollection" @onItemsChange="(items) => productOfTradeCollection = [...items]"/>
          </b-tab>

          <Produto v-if="importacaoManual" />

          <b-tab title="Impostos/Taxas" :active="store.currentTab === 5" :disabled="store.currentTab !== 5">
            <b-row>
              <b-col md="2">
                <b-form-group id="base-icms" label-for="base-icms" label="Base ICMS">
                  <money class="form-control" v-model="formImpostos.icmsBase" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="valor-icms" label-for="valor-icms" label="Vlr.ICMS">
                  <money class="form-control" v-model="formImpostos.icmsValue" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="base-icms-st" label-for="base-icms-st" label="Base ICMS ST">
                  <money class="form-control" v-model="formImpostos.icmsBaseST" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="base-icms-st" label-for="base-icms-st" label="Base.ICMS ST">
                  <money class="form-control" v-model="formImpostos.vBCST" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="valor-icms-st" label-for="valor-icms-st" label="Vlr.ICMS ST">
                  <money class="form-control" v-model="formImpostos.sTValue" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="valor-icms-str-fcp" label-for="valor-icms-st-fcp" label="Vlr.FCP ST">
                  <money class="form-control" v-model="formImpostos.vFCPST" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group id="desconto" label-for="desconto" label="Desconto">
                  <money class="form-control" v-model="formImpostos.desconto" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="valorFrete" label-for="valorFrete" label="Frete">
                  <money class="form-control" v-model="formImpostos.valorFrete" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="seguro" label-for="seguro" label="Seguro">
                  <money class="form-control" v-model="formImpostos.seguro" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="despesas" label-for="despesas" label="Outras Despesas">
                  <money class="form-control" v-model="formImpostos.outrasDespesas" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="ipiValue" label-for="ipiValue" label="Valor IPI">
                  <money class="form-control" v-model="formImpostos.ipiValue" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="2">
                <b-form-group id="total-prodtuos" label-for="total-produtos" label="Total Produtos">
                  <money class="form-control" v-model="valorProdutos" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="valor-total" label-for="valor-total" label="Total Notal">
                  <money class="form-control" v-model="total" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Transportador / Volumes" :active="store.currentTab === 6" :disabled="store.currentTab !== 6">
            <b-card-text>Transportador / Volumes</b-card-text>
            <b-row>
              <b-col md="5">
                <b-form-group label="Razão Social" label-for="transportador">
                  <VueSelect ref="buscarTransportador" :filterable="false" v-model="transportador"
                    @input="setTransportador" :options="transportadores" @search="onSearchTransportador">
                    <template slot="no-options">
                      Informe o nome do Transportador
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="numero" label-for="numero" label="CPF/CNPJ">
                  <b-form-input disabled id="cnpj_cpf" name="cnpj_cpf" v-model="formTransportador.cnpj_cpf"
                    v-mask="['###.###.###-##', '##.###.###/####-##']">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group id="tipo_frete" label-for="tipo_frete" label="Frete">
                  <VueSelect ref="buscarTipoFrete" :filterable="false" v-model="formTransportador.tipo_frete"
                    :options="tiposFrete">
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo }} - {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }} - {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="veiculo" label-for="veiculo" label="Veículo">
                  <VueSelect ref="buscarVeiculo" :filterable="false" v-model="formTransportador.veiculo"
                    :options="transportador.veiculos">
                    <template slot="no-options">
                      Informe o nome do Transportador
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.placa }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.placa }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group id="numero" label-for="numero" label="Endereço">
                  <VueSelect ref="buscarEndereco" :filterable="false" v-model="formTransportador.endereco"
                    :options="transportador.enderecos">
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.cep }} - {{ option.logradouro }} -
                        {{ option.bairro }} - {{ option.cidade.cidade }}/{{
                          option.cidade.uf
                        }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.cep }} - {{ option.logradouro }} -
                        {{ option.bairro }} - {{ option.cidade.cidade }}/{{
                          option.cidade.uf
                        }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group label="Quantidade">
                  <money class="form-control" v-model="formTransportador.quantidade" v-bind="quantityOptions">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Espécie">
                  <b-form-input id="especie" name="especie" v-model="formTransportador.especie">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Marca">
                  <b-form-input id="marca" name="marca" v-model="formTransportador.marca">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Peso Bruto">
                  <money class="form-control" v-model="formTransportador.pesoBruto" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Peso Líquido">
                  <money class="form-control" v-model="formTransportador.pesoLiquido" v-bind="configMoney">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Numeração">
                  <b-form-input id="numeracao" name="numeracao" v-model="formTransportador.numeracao">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab title="Pagamento" :active="store.currentTab === 7" :disabled="store.currentTab !== 7">
            <b-row>
              <b-col md="6">
                <b-form-group label="Forma de Pagamento">
                  <VueSelect label="descricao" v-model="pagamento" class="busca-cliente" ref="buscaPagamentos"
                    id="buscaPagamentos" :options="pagamentos" @input="changePagamentos">
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="numero" label-for="numero" label="Valor (Enter)">
                  <InputMoney ref="valorPagamento" id="valorPagamento" labelBold inputClass="form-control input"
                    :onEnter="adicionarPagamento" v-model="valorPgto" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="id_plano_contas" label-for="id_plano_contas" label="Plano de Contas">
                  <b-form-select v-model="form.id_plano_contas" placeholder="">
                    <b-form-select-option v-for="p in plano_contas" :key="p.id + 'plano_contas'" :value="p.id">
                      {{ p.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <button ref="addPagamento" class="btn btn-secondary font-weight-bold mb-10" @click="adicionarPagamento">
                  + Adicionar Pagamento
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="listaPagamentos.length > 0">
                <b-col class="busca">
                  <TableApp item="pagamento" subTitulo="" :data="listaPagamentos[0].range" :fields="fields"
                    classFilterColumn="valor" ref="valor">
                    <template slot="customRow" scope="props">
                      <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'id'">
                        <div class="d-flex align-items-center">
                          <div class="font-weight-bold text-muted">
                            {{ props.rowData.originalIndex + 1 }}
                          </div>
                        </div>
                      </span>
                      <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'valor'">
                        <div class="d-flex align-items-center">
                          <div @click="changePagamento(props.rowData.originalIndex, props.rowData.value)"
                            class="font-weight-bold text-muted cursor-pointer">
                            {{ numberToReal(props.rowData.value, 2) }}
                          </div>
                        </div>
                      </span>
                      <span slot="quantidade" class="pl-0 pt-8" v-if="props.field == 'vencimento'">
                        <div class="d-flex align-items-center">
                          <div class="font-weight-bold text-muted cursor-pointer"
                            @click="selectVencimento(props.rowData.originalIndex, props.rowData.date)">
                            {{ props.rowData.date | moment("DD/MM/YYYY") }}
                          </div>
                        </div>
                      </span>
                    </template>
                  </TableApp>
                </b-col>
              </b-col>
              <b-col md="12">
                <b-form-group label="Informações Complementares">
                  <b-form-textarea id="observacao" name="observacao" v-model="form.observacao" rows="3"
                    max-rows="6"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <TableApp class="mt-10" item="pagamento" titulo="Pagamentos" :data="listaPagamentos"
                  :fields="fieldsPagamentosRealizados" classFilterColumn="formaPagamento" ref="formaPagamento">
                  <template slot="customRow" scope="props">
                    <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'forma_pagamento'">
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold text-muted">
                          {{ props.rowData.descricao }}
                        </div>
                      </div>
                    </span>
                    <span slot="quantidade" class="pl-0 pt-8" v-if="props.field == 'valor'">
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold text-muted">
                          R$ {{ numberToReal(props.rowData.value, 2) }}
                        </div>
                      </div>
                    </span>
                    <span slot="actions" v-if="props.field == 'actions'">
                      <div class="row-actions" style="text-align: right !important">
                        <span style="overflow: visible; position: relative; width: 130px">
                          <div class="action-buttons">
                            <button @click="removePagamento(props.rowData, props.rowData.originalIndex)"
                              class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2">
                              <TrashCanIcon :size="16" />
                            </button>
                          </div>
                        </span>
                      </div>
                    </span>
                  </template>
                </TableApp>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <div v-if="canSaveCompra">
              <button :disabled="(store.currentTab === 0)" class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious">
                Anterior
              </button>
              <button type="submit" ref="submit_nova_compra" class="btn btn-primary font-weight-bold"
                @click="salvarCompra">
                Salvar
              </button>
              <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold"
                @click="confirmCancel('comprasLista')">
                Cancelar
              </button>
            </div>
            <div v-else>
              <button :disabled="(store.currentTab === 0)" class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious">
                Anterior
              </button>
              <button class="btn btn-primary font-weight-bold" @click="handleNext" :disabled="(store.currentTab === 7)">
                Proximo
              </button>
              <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold"
                @click="confirmCancel('comprasLista')">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-calendar" title="Ajustar vencimento" size="sm" centered>
      <div class="mx-auto">
        <b-calendar v-model="vencimentoSelected" locale="pt-BR"></b-calendar>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="salvarVencimento" ref="transmitir_nfe">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-pagamento" title="Ajustar pagamento" size="sm" centered>
      <div class="mx-auto">
        <b-form class="form" @submit.stop.prevent="salvarPagamento">
          <money @input="changePagamentoEdited" class="form-control" id="valorPagamento" name="valorPagamento"
            :value="pagamentoSelected" v-bind="configMoney">
          </money>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="salvarPagamento" ref="transmitir_nfe">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="importacao-manual" title="Definir importação" size="md" centered>
      <h5 style="text-align: center;">Como você deseja realizar a importação da compra?</h5>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="() => {
          cancel();
          handleSair();
        }">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="() => {
          importacaoManual = false;
          firstLoad = false;
          ok();
        }">
          Importar via xml
        </b-button>
        <b-button variant="primary" @click="() => {
          importacaoManual = true;
          firstLoad = false;
          store.currentTab = 1;
          ok();
        }" ref="lancar-manual">
          Lançar Manual
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FormatMixin from "../../Application/mixins/FormatMixin";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import CompraData, { CompraDataRequired } from "./CompraData";
import ImpostosData from "./ImpostosData";
import FornecedorData from "./FornecedorData";
import TransportadorData from "./TransportadorData";
import TableApp from "../../../components/Table/Table3.vue"
import _ from "lodash";
import { mask } from "vue-the-mask";
import InputMoney from "../../Pdv/components/Form";
import { Money } from "v-money";
import { MONEY as settingMoney, quantityOptions, priceOptions } from "@/modules/Application";
import { QuantidadesCompra, ItemsCompora } from "./componentes";
import PagamentosMixin from "../../Pdv/mixins/PagamentosMixin";
import InstallmentsMixin from "../../Application/mixins/InstallmentsMixin";
import { store } from "../storeCompra";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Produto from "./componentes/ProdutoTab.vue";

export default {
  name: "app-compra-form",
  props: {
    url: {
      type: String,
    },
    compra: {
      type: Object,
      default: () => { },
    },
  },
  directives: {
    mask,
  },
  components: {
    VueSelect,
    InputMoney,
    ItemsCompora,
    Money,
    QuantidadesCompra,
    TableApp,
    TrashCanIcon,
    Produto,
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
  ],
  data() {
    return {
      store,
      form: {},
      quantityOptions,
      priceOptions,
      vencimentoSelected: null,
      indexVencimento: null,
      selectedPagamento: null,
      firstLoad: true,
      importacaoManual: false,
      naturezaError: false,
      formFornecedor: {},
      formImpostos: {},
      pagamentoSelected: null,
      formTransportador: {},
      pagamentosEditados: [],
      formPagamento: {},
      naturezas: [],
      natureza: null,
      cfop: null,
      fornecedores: [],
      fornecedor: null,
      transportadores: [],
      transportador: {},
      hasXmlErrors: false,
      inputFilled: false,
      veiculo: {},
      configMoney: settingMoney,
      productOfTradeCollection: [],
      produtos: [],
      produto: null,
      xmlFile: null,
      valorTotal: "0",
      valorProdutos: "0",
      tiposFrete: [],
      pagamentos: [],
      valorPgto: 0,
      valorPagoCompra: 0,
      listaPagamentos: [],
      pagamento: {},
      plano_contas: [],
      multiplicar: 1,
      divisao: 2,
      fields: [
        {
          label: "",
          field: "id",
          slot: true,
          width: "10%",
          sortable: false
        },
        {
          label: "Valor R$",
          field: "valor",
          slot: true,
          width: "45%",
          sortable: false
        },
        {
          label: "Vencimento",
          field: "vencimento",
          slot: true,
          sortable: false,
          width: "45%"
        },
      ],
      fieldsPagamentosRealizados: [
        {
          label: "",
          field: "forma_pagamento",
          slot: true,
          width: "45%",
          sortable: false
        },
        {
          label: "",
          field: "valor",
          slot: true,
          width: "45%",
          sortable: false
        },
        {
          label: "",
          field: "actions",
          slot: true,
          width: "10%",
          sortable: false
        },
      ],
    };
  },
  validations: {
    form: CompraDataRequired,
  },
  watch: {
    compra(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.productOfTradeCollection = newValue.produtos;
        this.listaPagamentos = newValue.pagamentos;
        this.fornecedor = newValue.fornecedor;
        this.impostos = newValue.impostos;
        this.transportador = newValue.transportador;
      }
    },
    'store.formProduto': {
      handler(value) {
        this.productOfTradeCollection = value.produtos;
      }, deep: true
    },

    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorTotal = newValue;
      }
    },
    valorTotal(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.valorPgto === 'R$ 0,00') {
          this.valorPgto = this.numberToReal(newValue, 2)
        }
      }
    },
    totalProdutos(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorProdutos = newValue;
      }
    },
    valorPago(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorPagoCompra = newValue;
        this.checkValorPago();
      }
    },
    'store.currentTab'(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (oldValue === 0 && newValue === 1 && !this.importacaoManual) {
          if (!this.xmlFile && !this.$route.params.id_manifesto) {
            this.$toast.error("Selecione um arquivo XML para prosseguir!")
            this.hasXmlErrors = true;
            this.store.currentTab = oldValue
          }
        }
        if (oldValue === 1 && newValue === 2) {
          if (!this.form.natureza) {
            this.$toast.error("Selecione uma natureza para prosseguir!")
            this.naturezaError = true;
            this.store.currentTab = oldValue
          }
        }
        if (this.importacaoManual && oldValue === 3 && newValue === 4) this.store.currentTab = 5;
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    ;
    if (!Object.keys(this.$route.params).length > 0) this.$bvModal.show('importacao-manual')
  },
  async created() {
    this.store.currentTab = 0;
    this.getDataInit();
    if (this.$route?.params?.compraData) {
      const { compraData } = this.$route.params;
      this.firstLoad = false;
      this.importacaoManual = true;
      this.store.currentTab = 1;
      this.natureza = compraData.natureza;
      this.form.natureza = compraData.natureza;
      this.cfop = compraData.natureza.cfop.codigo;
      this.form.dataEmissao = compraData.dataEmissao;
      this.form.dataEntrada = compraData.dataEntrada;
      this.form.chaveDeAcesso = compraData.chaveDeAcesso;
      this.form.serieNFe = compraData.serieNFe;
      this.form.modeloNFe = compraData.modeloNFe;
      this.form.numeroNFe = compraData.numeroNFe;
      this.formFornecedor = compraData.fornecedor;

      if (compraData.arquivoXml) {
        this.formFornecedor.cep = compraData.fornecedor.endereco[0].cep ?? null;
        this.formFornecedor.logradouro = compraData.fornecedor.endereco[0].logradouro ?? null;
        this.formFornecedor.numero = compraData.fornecedor.endereco[0].numero ?? null;
        this.formFornecedor.complemento = compraData.fornecedor.endereco[0].complemento ?? null;
        this.formFornecedor.bairro = compraData.fornecedor.endereco[0].bairro ?? null;
        this.formFornecedor.uf = compraData.fornecedor.endereco[0].uf ?? null;
        this.formFornecedor.cidade = compraData.fornecedor.endereco[0].cidade.cidade ?? null;
        this.formFornecedor.fone = compraData.fornecedor.telefone[0].fone ?? null;
      }
      this.form.id_plano_contas = compraData?.plano_contas?.id ?? null;
      this.fornecedor = compraData.fornecedor;
      this.formImpostos = compraData.impostos;
      this.listaPagamentos = compraData.pagamentos;
      this.form.pagamentos = this.listaPagamentos;
      this.transportador = compraData?.transportador ?? {};
      this.formTransportador = compraData?.transportador ?? {};
      this.form.observacao = compraData?.observacao ?? "";
    }
    if (this.$route.params?.id_manifesto) {
      this.setLoading(true);
      try {
        const response = await this.$http.post("/manifesto/importar-nfe", {
          id_manifesto: this.$route.params?.id_manifesto,
        });

        this.form = response.data;
        this.form.dataEntrada = this.thisDate();

        const pagamento = this.form.pagamentos

        this.listaPagamentos.push(pagamento);
        this.checkValorPago(pagamento);

        this.formFornecedor = {
          ...this.form.fornecedor,
          ...this.form.fornecedor.endereco[0],
          cidade: this.form.fornecedor.endereco[0].cidade.cidade,
          fone: this.form.fornecedor.telefone[0].fone,
        };
        this.fornecedor = this.formFornecedor;

        this.productOfTradeCollection = this.form.produtos;
        this.formImpostos = this.form.impostos;

        if (this.form.transportador) {
          this.formTransportador = this.form.transportador;
          this.transportador = this.formTransportador;
          this.formTransportador.tipo_frete = this.form.transportador.mod_frete ?? 9;
          this.formTransportador = {
            ...this.formTransportador,
            ...this.formTransportador.vol,
          };
        }
        this.store.currentTab = 1;
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false);
      this.firstLoad = false;
    }

  },
  methods: {
    ...mapActions(["setLoading"]),
    openNewFornecedor() {
      window.open(this.$router.resolve({ name: 'pessoasNova' }).href, '_blank')
    },
    async getDataInit() {
      this.form = new CompraData({});
      this.formImpostos = new ImpostosData({});
      this.formFornecedor = new FornecedorData({});
      this.formTransportador = new TransportadorData({});
      this.tiposFrete = await this.getTiposFrete();
      this.getPagamentos();
      this.plano_contas = await this.getPlanoContas(1);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async salvarPagamento() {
      this.listaPagamentos[0].range[this.selectedPagamento].value = this.pagamentoEditado;

      const indexEditPagamento = this.pagamentosEditados.findIndex(pagamento => pagamento?.index === this.selectedPagamento)
      if (indexEditPagamento === -1) {
        this.pagamentosEditados.push({
          index: this.selectedPagamento,
          value: this.pagamentoEditado
        })
      } else {
        this.pagamentosEditados.splice(indexEditPagamento, 1, {
          index: this.selectedPagamento,
          value: this.pagamentoEditado
        })
      };
      const pagamentosEditadosTotal = this.pagamentosEditados.reduce((acc, pagamento) => {
        if (pagamento?.value) return acc + pagamento.value
        else return acc
      }, 0)
      const editPagamentoCount = this.pagamentosEditados.length

      const pagamentoRestante = this.listaPagamentos[0].value - pagamentosEditadosTotal;

      const valorParcelasRestantes = Math.round(pagamentoRestante / (this.listaPagamentos[0].range.length - editPagamentoCount) * 100) / 100;
      this.listaPagamentos[0].range = this.listaPagamentos[0].range.map((item, index) => {
        const hasInPagamentosEditado = this.pagamentosEditados.find(pagamento => pagamento.index === index)
        return {
          ...item,
          value: hasInPagamentosEditado ?
            hasInPagamentosEditado.value
            :
            index !== this.selectedPagamento ?
              valorParcelasRestantes
              :
              this.pagamentoEditado
        }
      })

      this.$bvModal.hide('modal-pagamento')
    },
    selectVencimento(index, date) {
      this.$bvModal.show('modal-calendar')
      this.indexVencimento = index
      this.vencimentoSelected = date
    },
    changePagamento(pagamentoIndex, value) {
      this.$bvModal.show('modal-pagamento')
      this.selectedPagamento = pagamentoIndex
      this.pagamentoSelected = value
    },
    changePagamentoEdited(valor) {
      this.pagamentoEditado = valor;
    },
    salvarVencimento() {
      this.listaPagamentos[0].range[this.indexVencimento].date = this.vencimentoSelected;
      this.$bvModal.hide('modal-calendar')
    },
    handleNext() {
      if (this.store.currentTab === 7) return;
      this.store.currentTab++
    },
    handlePrevious() {
      if (this.store.currentTab === 0 || (this.importacaoManual && this.store.currentTab === 1)) return;
      if (this.importacaoManual && this.store.currentTab === 5) {
        this.store.currentTab = 3;
        return;
      }
      this.store.currentTab--
    },
    removeItem(index) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Deseja remover o item?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.productOfTradeCollection.splice(index, 1);
            this.$alert.success("Item removido com sucesso!");
          }
        });
    },
    onSearchCFOP(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCFOP(loading, search, this);
      }
    },
    searchCFOP: _.debounce(async (loading, search, vm) => {
      vm.naturezas = await vm.getNaturezaByDescricao(search);
      loading(false);
    }, 350),

    setNatureza(natureza) {
      this.form.natureza = natureza;
      this.cfop = natureza.cfop.codigo;
      this.naturezaError = false;
    },

    onSearchFornecedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchFornecedor(loading, search, this);
      }
    },

    searchFornecedor: _.debounce(async (loading, search, vm) => {
      vm.fornecedores = await vm.getFornecedorByNome(search);
      loading(false);
    }, 350),

    onSearchTransportador(search, loading) {
      if (search.length) {
        loading(true);
        this.searchTransportador(loading, search, this);
      }
    },

    searchTransportador: _.debounce(async (loading, search, vm) => {
      vm.transportadores = await vm.getTransportadorByNome(search);
      loading(false);
    }, 350),

    setFornecedor(fornecedor) {
      this.formFornecedor = fornecedor;
      this.formFornecedor.cep = fornecedor.enderecos[0].cep ?? null;
      this.formFornecedor.logradouro =
        fornecedor.enderecos[0].logradouro ?? null;
      this.formFornecedor.numero = fornecedor.enderecos[0].numero ?? null;
      this.formFornecedor.complemento =
        fornecedor.enderecos[0].complemento ?? null;
      this.formFornecedor.bairro = fornecedor.enderecos[0].bairro ?? null;
      this.formFornecedor.uf = fornecedor.enderecos[0].cidade.uf ?? null;
      this.formFornecedor.cidade =
        fornecedor.enderecos[0].cidade.cidade ?? null;
      this.formFornecedor.fone = fornecedor.fones[0].fone ?? null;
    },

    setTransportador(transportador) {
      this.formTransportador = transportador;
    },

    async setNotaFiscal(notafiscal) {
      this.form = notafiscal;
      this.productOfTradeCollection = notafiscal.produtos;

      if (this.productOfTradeCollection) {
        await this.productOfTradeCollection.map((item, key) => {
          if (typeof item.vinculoProduto !== "undefined") {
            this.productOfTradeCollection[key].unidade_medida =
              item.vinculoProduto.unidade_medida;

            if (!this.productOfTradeCollection[key].unidade_medida) {
              this.productOfTradeCollection[key].quantidade_conversao =
                item.quantidade;
              return;
            }

            if (
              this.productOfTradeCollection[key].unidade_medida
                .tipo_conversor == this.multiplicar
            ) {
              this.productOfTradeCollection[key].quantidade_conversao =
                this.productOfTradeCollection[key].quantidade_conversao *
                this.productOfTradeCollection[key].unidade_medida.conversor;

              return;
            }

            if (
              this.productOfTradeCollection[key].unidade_medida
                .tipo_conversor == this.divisao
            ) {
              this.productOfTradeCollection[key].quantidade_conversao =
                this.productOfTradeCollection[key].quantidade_conversao /
                this.productOfTradeCollection[key].unidade_medida.conversor;

              return;
            }
          }
        });
      }

      if (notafiscal.fornecedor) {
        this.fornecedor = notafiscal.fornecedor;
        this.formFornecedor.cnpj_cpf = notafiscal.fornecedor.cnpj_cpf ?? null;
        this.formFornecedor.ie_rg = notafiscal.fornecedor.ie_rg ?? null;
        this.formFornecedor.cep = notafiscal.fornecedor.endereco[0].cep ?? null;
        this.formFornecedor.logradouro =
          notafiscal.fornecedor.endereco[0].logradouro ?? null;
        this.formFornecedor.numero =
          notafiscal.fornecedor.endereco[0].numero ?? null;
        this.formFornecedor.bairro =
          notafiscal.fornecedor.endereco[0].bairro ?? null;
        this.formFornecedor.cidade =
          notafiscal.fornecedor.endereco[0].cidade.cidade ?? null;
        this.formFornecedor.uf = notafiscal.fornecedor.endereco[0].uf ?? null;
        this.formFornecedor.complemento =
          notafiscal.fornecedor.endereco[0].complemento ?? null;

        if (notafiscal.fornecedor.telefone !== undefined) {
          this.formFornecedor.fone = notafiscal.fornecedor.telefone[0].fone ?? null;
        }
      }

      if (notafiscal.impostos) {
        this.formImpostos.desconto =
          (await notafiscal.impostos.desconto) ?? null;
        this.formImpostos.descontoPorcentagem =
          notafiscal.impostos.descontoPorcentagem ?? null;
        this.formImpostos.icmsBase =
          (await notafiscal.impostos.icmsBase) ?? null;
        this.formImpostos.icmsValue =
          (await notafiscal.impostos.icmsValue) ?? null;
        this.formImpostos.ipiValue =
          (await notafiscal.impostos.ipiValue) ?? null;
        this.formImpostos.outrasDespesas =
          notafiscal.impostos.outrasDespesas ?? null;
        this.formImpostos.sTValue = (await notafiscal.impostos.sTValue) ?? null;
        this.formImpostos.seguro = (await notafiscal.impostos.seguro) ?? null;
        this.formImpostos.vFCPST = (await notafiscal.impostos.vFCPST) ?? null;
        this.valorProdutos = (await notafiscal.impostos.totalProdutos) ?? null;
        this.valorTotal = (await notafiscal.impostos.valor) ?? null;
        this.formImpostos.valorFrete =
          (await notafiscal.impostos.valorFrete) ?? null;
      }

      if (notafiscal.transportador) {
        this.transportador = notafiscal.transportador;
        this.formTransportador.cnpj_cpf = notafiscal.transportador.cnpj_cpf ?? null;
        this.formTransportador.tipo_frete =
          notafiscal.transportador.mod_frete ?? null;
        this.formTransportador.quantidade =
          notafiscal.transportador.vol.quantidade ?? null;
        this.formTransportador.pesoLiquido =
          notafiscal.transportador.vol.pesoLiquido ?? null;
        this.formTransportador.pesoBruto =
          notafiscal.transportador.vol.pesoBruto ?? null;
      }

      if (notafiscal.pagamentos) {
        this.listaPagamentos.push(notafiscal.pagamentos);
      }
    },

    async setXMLFile(event) {
      this.xmlFile = await event.target.files[0];
    },
    async novaImportacao() {
      this.form = {};
      this.formTransportador = {};
      this.formImpostos = {};
      this.formFornecedor = {};
      this.productOfTradeCollection = null;
      this.listaPagamentos = [];
    },
    async importXML() {
      if (!this.xmlFile) {
        return this.$toast.warning("Nenhum arquivo xml selecionado!");
      }
      await this.novaImportacao();

      this.form = null;
      this.productOfTradeCollection = null;

      const formData = new FormData();
      formData.append("arquivo-xml", this.xmlFile);

      const submitButton = this.$refs["importar_xml"];

      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.setAttribute("disabled", "");
      this.setLoading(true);

      try {
        const { data } = await this.$http.post("/compras/extair-xml", formData);
        this.setLoading(false);
        data.dataEntrada = new Date();
        await this.setNotaFiscal(data);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
        this.$toast.success("Arquivo xml importado com sucesso!");
        return data;
      } catch (error) {
        this.setLoading(false);
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
        return null;
      }
    },
    handleSair() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Cancelar o lançamento em andamento?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "comprasLista" });
          } else {
            if (this.firstLoad && !Object.keys(this.$route.params).length !== 0) {
              this.$bvModal.show('importacao-manual')
            }
          }
        });
    },
    async salvarCompra() {
      const submitButton = this.$refs["submit_nova_compra"];
      if (parseFloat(this.valorPagoCompra) > 0) {
        if (
          parseFloat(this.valorPagoCompra).toFixed(0) <
          parseFloat(this.total).toFixed(0)
        ) {
          return this.$toast.warning(
            "Valor pago deve ser igual ao total da compra!"
          );
        }
      }

      if (this.natureza == null) {
        this.$toast.warning("Selecione a natureza de operação!");
        return;
      }

      if (this.fornecedor == null) {
        this.$toast.warning("Selecione a fornecedor!");
        return;
      }

      if (this.listaPagamentos.length === 0) {
        this.$toast.warning("Informe ao menos um pagamento!");
        return;
      }

      let produtosSemUN = [];
      if (this.productOfTradeCollection) {
        this.productOfTradeCollection.map((item, key) => {
          if (!item.unidade_medida) {
            key = key + 1;
            produtosSemUN.push(key);
          }
        });
      }

      if (produtosSemUN.length > 0) {
        const message = !this.importacaoManual ?
          "Selecione uma unidade medida do item da posição: " + produtosSemUN
          :
          "Produto cadastrado sem unidade de medida! Produto da posição: " + produtosSemUN
        return this.$toast.warning(message);
      }
      if (this.listaPagamentos.length === 0) {
        return this.$toast.warning("Selecione uma forma de pagamento!");
      }

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        this.form.impostos = this.formImpostos;
        this.form.impostos.valor = this.total;
        this.form.impostos.totalProdutos = this.valorProdutos;
        this.form.fornecedor = this.fornecedor;
        this.form.transportador = this.transportador;
        this.form.produtos = this.productOfTradeCollection;
        this.form.pagamentos = this.listaPagamentos;

        if (this.importacaoManual) this.form = {
          ...this.form,
          importacao_manual: true,
        };

        if (this.$route.params?.compraData) {
          await this.$http.post('/compras/update', {
            id: this.$route.params?.compraData?.id,
            ...this.form
          });
        } else await this.$http.post(this.url, this.form);

        this.$router.push({ name: "comprasLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    changePagamentos(selectedPagamento) {
      this.pagamento = selectedPagamento;
      this.$nextTick(() => {
        this.$refs.valorPagamento.$el.querySelector("input").focus();
      });
    },
    checkValorPago(valor = 0) {
      if (this.valorPagoCompra === 0 && valor === 0) {
        this.valorPgto = this.valorReal(this.total);
        return;
      }

      const valorPagar = this.total - (this.valorPagoCompra + valor);
      this.valorPgto = valorPagar < 0 ? "0,00" : this.valorReal(valorPagar);
      this.valorPagoCompra += valor;
    },
    async adicionarPagamento() {
      const valor = await this.valorUS(this.valorPgto);

      let pagamento = {
        value: valor,
        ...this.pagamento,
      };

      if ((valor > 0 && pagamento.descricao) || (pagamento.descricao === 'Nenhum')) {
        const range = pagamento.descricao !== 'Nenhum' ? this.calculateInstallments(
          pagamento,
          parseFloat(pagamento.value),
        ) : [{
          date: '-',
          value: 0,
        }];

        pagamento = {
          ...pagamento,
          range,
        };

        this.listaPagamentos.push(pagamento);

        this.checkValorPago(pagamento.value);

        this.changePagamentos({});

        this.$alert.success("Adicionado com sucesso!");
      }
    },
    removePagamento(indice) {
      this.listaPagamentos.splice(indice, 1);
      this.checkValorPago();
      this.$alert.success("Removido com sucesso!");
      this.$nextTick(() => {
        this.$refs.buscaPagamentos.$el.querySelector("input").focus();
      });
    },
    getRangePayment(payment) {
      if(!payment) return 0;
      return payment.reduce((total, item) => total + item.value, 0);
    },
  },
  computed: {
    total() {
      const produtos = this.productOfTradeCollection;
      const aux = produtos.reduce((total, item) => total + (item.total), 0);
      let valor = isNaN(aux) ? 0 : aux;

      if (this.formImpostos.desconto) {
        valor -= this.formImpostos.desconto;
      }

      if (this.formImpostos.outrasDespesas) {
        valor += parseFloat(this.formImpostos.outrasDespesas);
      }

      if (this.formImpostos.seguro) {
        valor += parseFloat(this.formImpostos.seguro);
      }

      if (this.formImpostos.valorFrete) {
        valor += parseFloat(this.formImpostos.valorFrete);
      }

      if (this.formImpostos.ipiValue) {
        valor += parseFloat(this.formImpostos.ipiValue);
      }

      if (this.formImpostos.sTValue) {
        valor += parseFloat(this.formImpostos.sTValue);
      }

      if (this.formImpostos.vFCPST) {
        valor += parseFloat(this.formImpostos.vFCPST);
      }

      const valueToPay = valor - (this.valorPago ?? 0);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.valorPgto = (parseFloat(valueToPay < 0 ? 0 : valueToPay).toFixed(2));

      return valor.toFixed(2);
    },
    totalProdutos() {
      const produtos = this.productOfTradeCollection;
      let valor = produtos.reduce((total, item) => total + item.total, 0);

      return valor;
    },
    valorPagamento() {
      if (this.listaPagamentos.length === 0) return 0
      else return this.listaPagamentos[0].valor
    },
    canSaveCompra() {
      return this.store.currentTab === 7;
    },
    valorPago() {
      if (!this.listaPagamentos || !this.listaPagamentos?.length) {
        return;
      }

      return this.listaPagamentos.reduce(
        (total, item) => total + this.getRangePayment(item?.range),
        0
      );
    },
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    toolbarDisplay() {
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
  },
};
</script>

<style>
.hasErrors .vs__dropdown-toggle {
  border: 2px solid red;
}

.hasErrors .vs__clear,
.hasErrors .vs__open-indicator {
  fill: red;
}

.inputFilled .vs__dropdown-toggle {
  border: 2px solid #1BC5BD;
  /* color: #394066; */
}

.inputFilled .vs__clear,
.inputFilled .vs__open-indicator {
  fill: #1BC5BD;
}

.xmlError .custom-file-input {
  border: 2px solid red;
}

.busca-produto .vs__search {
  font-weight: 400;
  font-size: 1.45rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
}

.busca-produto .vs__dropdown-toggle {
  border: 0px !important;
}

.busca-produto .vs__actions {
  display: none;
}


.busca-produto .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-produto .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.busca-cliente .vs__search {
  font-size: 18px;
}

.busca-cliente .vs__dropdown-toggle {
  color: #ee7361;
  background-color: #fff;
  border: 2px solid #dedede;
  height: 40px;
}

.busca-cliente .vs--open .vs__dropdown-toggle {
  background-color: #fff;
  border: 2px solid #ee7361;
}

.busca-cliente .vs__actions {
  display: none;
}

.busca-cliente .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus {
  background-color: #ee73615e !important;
  color: #ee7361 !important;
}

.busca-cliente .vs__dropdown-menu {
  color: #ee7361 !important;
}

.busca-cliente .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-cliente .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-pgto {
  font-weight: bold;
  font-size: 18px;
  color: #4f4f4f;
}

.add-pgto {
  font-weight: bold;
  font-size: 22px;
  color: #ee7361;
  float: right;
}

.input-quantidade {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: transparent;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: right;
}

.input-quantidade:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  border: 0px;
  background-color: transparent;
  color: #fff;
}

.card {
  border: 0;
}

h4 {
  color: #6d747c;
  /* font-weight: 900; */
}

.btn-finalizar-venda {
  background-color: #eee5ff !important;
  font-size: 1.25rem !important;
  text-align: center !important;
  cursor: pointer;
}

.card-total-right p,
.card-total-left p {
  color: #fff !important;
}

.card-header h5,
.card-header h3 {
  margin-bottom: 0rem;
}

.card-header .form-control {
  height: auto;
  margin-top: 0px;
}

.card-header .busca-produto {
  margin-top: 2px;
}

.card-titulo-itens .card-body {
  padding: 5px 0 !important;
}

.card-itens .table tr th {
  padding: 5px;
  font-size: 12px;
}

.card-itens .table tr td,
.card-total-right p,
.card-total-left p {
  font-size: 12px;
}

.tableItensCompra {
  max-height: 300px !important;
  height: calc(100vh - 395px) !important;
}

.card-total-left,
.card-total-right {
  height: 80px !important;
}

.btn.btn-secondary {
  color: #ee7361;
  background-color: #fff;
  border-color: #fff;
}
</style>
