/* eslint-disable */
import Vue from "vue";
import moment from "moment";
import swal from "sweetalert";
import VueToast from "vue-toast-notification";
import { store } from '@/modules/NFe/storeNfe';
import { filtersStore } from '@/components/FilterFields/filtersStore';
import { store as OSStore } from '@/modules/OS/storeOS';
import { priceOptions, quantityOptions } from "@/modules/Application";
import { adminPermissionStore } from "@/adminPermissionStore"
Vue.use(VueToast, {
  position: "top-right",
  duration: 8000
});

export default {
  methods: {
    confirmCancel(routeToExit) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Cancelar o lançamento em andamento?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: routeToExit });
          }
        });
    },
    async getRequestAdmin(callBackFunction) {
      if (!adminPermissionStore.requestPermission) {
        callBackFunction()
        return;
      };
      const isPermissionGranted = await this.$swal.fire({
        title: 'Informe a senha do Administrador',
        showCancelButton: true,
        confirmButtonText: `Continuar`,
        cancelButtonText: `Cancelar`,
        input: "password",
        preConfirm: async (password) => {
          try {
            const { data } = await this.$http.post('/usuario/check-adm', {
              password,
            })
            if (!data.status) {
              return this.$swal.showValidationMessage(data.msg);
            }
            this.$toast.success(data.msg);
            return true;
          } catch (error) {
            this.$swal.showValidationMessage(data.msg);
          }
          return false;
        },
      })
      const isCanceled = isPermissionGranted?.isDismissed
      if (isPermissionGranted && !isCanceled) {
        try {
          callBackFunction()
        } catch (error) {
          console.log(error)
        }
      }
    },
    async getTotaisDashboard() {
      try {
        const { data } = await this.$http.post("/dashboard/totais");
        return data.data;
      } catch (error) {
        return [];
      }
    },
    async getFormasPagamentos() {
      try {
        const { data } = await this.$http.post("/financeiro/formas-pagamento");
        return data;
      } catch (error) {
        console.log(error)
        return [];
      }
    },
    async getTotaisNfe(dataToSend) {
      try {
        const { data } = await this.$http.post("/nfe/calcular-totais-nfe", {
          dados: dataToSend
        });
        return data;
      } catch (error) {
        console.log(error)
        return [];
      }
    },
    fireOnboardToast({ id, title, content, buttonText, nextRouterStep, fowardStep }) {
      const h = this.$createElement
      const toastId = `onboard-${id}`
      const $okButton = h(
        'b-button',
        {
          on: {
            click: () => {
              if (!nextRouterStep) {
                this.$bvToast.hide(toastId)
                fowardStep();
                return
              } else {
                fowardStep();
                this.$bvToast.hide(toastId)
                this.$router.push({ name: nextRouterStep })
              }
            }
          }
        },
        buttonText
      )
      const $closeButton = h(
        'b-button',
        {
          on: {
            click: () => {
              this.$bvToast.hide(toastId)
            }
          }
        },
        buttonText === 'Sim' ? 'Não' : 'Fechar'
      )
      const vNodesMsg = h('p', { class: ['mb-2'] }, content)

      this.$bvToast.toast([vNodesMsg, $okButton, $closeButton], {
        id: toastId,
        title: title,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        appendToast: false,
        noAutoHide: true,
        noCloseButton: true
      })
    },
    async criarNfe(dataToSend, tpNF) {
      try {
        const { data } = await this.$http.post("/nfe/create-nfe", {
          dados: { ...dataToSend, tpNF, }
        });
        console.log('Data Sended sucessfully')
        return data.id;
      } catch (error) {
        console.log(error)
        return;
      }
    },
    async transmitirNfeApi(id, sendEmail, printDanfe, generateBoleto) {
      try {
        const response = await this.$http.post("/nfe/transmitter-nfe", {
          id,
          'send-email': sendEmail,
          'print-danfe': printDanfe,
          'generate-boleto': generateBoleto
        });
        console.log('NFe Transmitida com sucesso')
        return response;
      } catch (error) {
        console.log(error)
        return;
      }
    },
    async gerarPdf(id, imprimirDuplicata) {
      try {
        const pdfUrl = await this.$http.post("/nfe/print-nfe", {
          id,
          impressao_duplicata: imprimirDuplicata
        });
        return pdfUrl
      } catch (error) {
        console.log(error)
        return null;
      }
    },
    formatarCampo(campoTexto) {
      if (campoTexto.length <= 11) {
        return campoTexto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }
      return campoTexto.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    },
    getParcelas(stringPagamento) {
      const auxArray = stringPagamento.split('x')[0].split(' ');
      const parcelas = parseInt(auxArray[auxArray.length - 1]);
      return parcelas;
    },
    async getTipoPessoa() {
      try {
        const { data } = await this.$http.post("/sistema/tipo/pessoa");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getClientes(nome) {
      try {
        const { data } = await this.$http.post("/pessoa/nome", {
          nome
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getFornecedores(nome) {
      try {
        const { data } = await this.$http.post("/pessoa/nome-fornecedor", {
          nome
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getTipoEndereco() {
      try {
        const { data } = await this.$http.post("/sistema/tipo/endereco");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getTipoFone() {
      try {
        const { data } = await this.$http.post("/sistema/tipo/fone");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getTipoEmail() {
      try {
        const { data } = await this.$http.post("/sistema/tipo/email");
        return data;
      } catch (error) {
        return [];
      }
    },
    async fetchFinalidades() {
      try {
        const { data } = await this.$http.post("/sistema/get-finalidade-nfe");
        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async fetchProdutos(search, typeFilter) {
      try {
        const { data } = await this.$http.post("/produtos/filter-item", {
          filter: search,
          typeFilter
        });
        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getCidade(filtro) {
      try {
        const { data } = await this.$http.post("/sistema/cidade", {
          filtro,
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getCidadeByUF(cidade, uf) {
      try {
        const { data } = await this.$http.post("/sistema/get-cidade-by-uf", {
          cidade,
          uf,
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getCFOPs() {
      try {
        const { data } = await this.$http.post("/sistema/cfop");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getCFOPByCodigo(codigo) {
      const { data } = await this.$http.post("/sistema/cfop-by-codigo", {
        codigo,
      });
      return data;
    },
    async getNaturezaByDescricao(descricao) {
      const { data } = await this.$http.post("/natureza/get-natureza", {
        descricao,
      });
      return data;
    },
    async getTransportadoras(search) {
      const { data } = await this.$http.post("/pessoa/get-transportador", {
        nome: search,
      });
      return data;
    },
    async getFretes() {
      const { data } = await this.$http.post("/sistema/get-tipo-frete");
      return data;
    },
    async getFornecedorByNome(nome) {
      const { data } = await this.$http.post("/pessoa/get-fornecedor-by-name", {
        nome,
      });
      return data;
    },
    async getGrupoByDescricao(descricao) {
      const { data } = await this.$http.post("/produtos/get-grupo-by-descricao", {
        descricao,
      });
      return data;
    },
    async getTransportadorByNome(nome) {
      const { data } = await this.$http.post("/pessoa/get-transportador", {
        nome,
      });
      return data;
    },
    async getCstCsosn() {
      try {
        const { data } = await this.$http.post("/sistema/cstcsosn");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getCstCest() {
      try {
        const { data } = await this.$http.post("/sistema/cest");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getCESTByCodigo(codigo) {
      const { data } = await this.$http.post("/sistema/cest-by-codigo", {
        codigo,
      });
      return data;
    },
    async getNCM() {
      try {
        const { data } = await this.$http.post("/sistema/ncm");
        return data;
      } catch (error) {
        return [];
      }
    },
    async getTributacao() {
      try {
        const { data } = await this.$http.post("/tributacao?per_page=150");
        return data.data;
      } catch (error) {
        return [];
      }
    },
    async getTributacaoByDescricao(descricao) {
      const { data } = await this.$http.post(
        "/tributacao/tributacao-by-description",
        { descricao }
      );
      return data;
    },
    async getPlanoContasFinanceiro(tipo, descricao) {
      try {
        const { data } = await this.$http.post("/financeiro/plano-de-contas", {
          tipo,
          descricao,
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getPlanoContas(tipo) {
      try {
        const { data } = await this.$http.post("/financeiro/plano-de-contas", {
          tipo,
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getPlanoContasContaReceber(descricao) {
      try {
        const { data } = await this.$http.post("/financeiro/plano-de-contas", {
          tipo: 2,
          descricao,
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getPlanoContasContaPagar(descricao) {
      try {
        const { data } = await this.$http.post("/financeiro/plano-de-contas", {
          tipo: 1,
          descricao,
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getCep(cep) {
      try {
        const { data } = await this.$http.post("/sistema/consulta-cep", { cep });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getUnidadeMedidas() {
      try {
        const { data } = await this.$http.post("/produtos/lista-un", {
          per_page: 150,
        });
        return data.data;
      } catch (error) {
        return [];
      }
    },
    async getGrupos() {
      try {
        const { data } = await this.$http.post("/produtos/lista-grupo", {
          per_page: 150,
        });
        return data.data;
      } catch (error) {
        return [];
      }
    },
    async getProdutoByDescricao(descricao) {
      const { data } = await this.$http.post("/produtos/filter-item", {
        filter: descricao,
      });
      return data;
    },
    async getCenqByCodigo(codigo) {
      const { data } = await this.$http.post("/sistema/cenq-by-codigo", {
        codigo,
      });
      return data;
    },
    async getCSTIPIByCodigo(codigo) {
      const { data } = await this.$http.post("/sistema/cst-ipi-by-codigo", {
        codigo,
      });
      return data;
    },
    async getCSTPISByCodigo(codigo) {
      const { data } = await this.$http.post("/sistema/cst-pis-by-codigo", {
        codigo,
      });
      return data;
    },
    async getAnpByCodigo(codigo) {
      const { data } = await this.$http.post("/sistema/anp-by-codigo", {
        codigo,
      });
      return data;
    },
    async getUF() {
      const { data } = await this.$http.post("/sistema/get-uf");
      return data;
    },
    async getAssinaturaByClient() {
      const { data } = await this.$http.post("/empresa/assinatura");
      return data;
    },
    async getConfigAssinaturasRevenda() {
      const { data } = await this.$http.post("/empresa/assinaturas-revenda");
      return data;
    },
    async getDadosRevenda(id) {
      const { data } = await this.$http.post("/empresa/get-cliente", {
        id_empresa: id,
      });
      return data;
    },
    async getClientesByRevenda(id) {
      const { data } = await this.$http.post("/empresa/lista-clientes", {
        id_revenda: id,
      });
      return data;
    },
    async getListaMCC(description) {
      const { data } = await this.$http.post("sistema/listar-ramos", {
        description,
      });
      return data;
    },
    async getBancosByEmpresa() {
      const { data } = await this.$http.post("/bancos/listar-contas");
      return data;
    },
    async getNutsDigital() {
      const { data } = await this.$http.post("/bancos/find-digital");
      return data;
    },
    async getTiposFrete() {
      const { data } = await this.$http.post("/sistema/get-tipo-frete");
      return data;
    },
    async getUNByDescricao(nome) {
      const { data } = await this.$http.post("/produtos/get-unidade-medida", {
        nome,
      });
      return data;
    },
    async getVendedorByNome(nome) {
      const { data } = await this.$http.post("/vendedor/get-vendedor", {
        nome,
      });
      return data;
    },
    async getPlans() {
      const { data } = await this.$http.post("/empresa/get-plans");
      return data;
    },
    async getTecnicos(search) {
      try {
        const { data } = await this.$http.post("/tecnico/get-tecnico", {
          search
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getObjeto(search) {
      try {
        const { data } = await this.$http.post("/objeto/get-objeto", {
          search
        });
        return data;
      } catch (error) {
        return [];
      }
    },
    async getPaises() {
      const { data } = await this.$http.post("/nfe/get-paises");
      return data;
    },
    async getModelosPos() {
      try {
        const { data } = await this.$http.post("/pos/modelos");
        return data;
      } catch (error) {
        return [];
      }
    },
    statusLabel(status) {
      switch (status) {
        case 1:
          return "Em processamento";
        case 2:
          return "Enviando";
        case 3:
          return "Cancelada";
        case 4:
          return "Autorizada";
        case 5:
          return "Erro na transmissão";
        case 8:
          return "Rejeitada";
        case 14:
          return "Inutilizada";
        case 15:
          return "Denegada";
        case null:
          return "Editando";
        default:
          return "";
      }
    },
    statusPedidoLabel(status) {
      switch (status) {
        case 1:
          return "Editando";
        case 3:
          return "Autorizado";
        case 4:
          return "Cancelado";
        case 5:
          return "Exportado";
        case 8:
          return "Importado NFe";
        case 9:
          return "Produção";
        case 10:
          return "Entregue";
        case 12:
          return "Faturado";
        case null:
          return "Editando";
        default:
          return "";
      }
    },
    statusMdfeLabel(status) {
      switch (status) {
        case 1:
          return "Editando";
        case 2:
          return "Autorizado";
        case 3:
          return "Cancelado";
        case 4:
          return "Encerrado";
        case 5:
          return "Em processamento";
        case 6:
          return "Rejeitado";
        case 7:
          return "Erro transmissão";
        case 631:
          return "Rejeição duplicidade evento";
        case 203:
          return "Emissor não habilitado";
        case null:
          return "";
        default:
          return "";
      }
    },
    statusOSLabel(status) {
      switch (status) {
        case 1:
          return "Aberto";
        case 2:
          return "Orcada";
        case 3:
          return "Autorizado";
        case 4:
          return "Cancelada";
        case 5:
          return "Exportada";
        case 6:
          return "Não autorizada";
        case 7:
          return "Análise cliente";
        case 8:
          return "Andamento";
        case 9:
          return "Finalizada";
        case 10:
          return "Aguardando componentes";
        case 11:
          return "Serviço concluído";
        case 12:
          return "Finalizada sem concerto";
        case 13:
            return "Pago";
        case 14:
            return "Faturada";
        case 15:
            return "Aguardando cliente";
        case null:
          return "Editando";
        default:
          return "";
      }
    },
    statusLabelPessoa(status) {
      return status === 'A' ? 'Ativo' : 'Inativo'
    },
    formatFones(fones) {
      var foneFormatado = "";
      fones.forEach((fone, key) => {
        foneFormatado += fone.fone;

        if (fones.length > 1) {
          foneFormatado += ", ";
        }
      });

      return foneFormatado;
    },
    formatEndereco(endereco) {
      var enderecoFormatado = "";
      var complemento = "";
      endereco.forEach((end, key) => {
        if (!end.cep) {
          return null;
        }

        if (end.complemento) {
          complemento = " - Comple: " + end.complemento;
        }

        enderecoFormatado +=
          end.logradouro +
          " - N: " +
          end.numero +
          " - Bairro: " +
          end.bairro +
          complemento +
          " - CEP: " +
          end.cep +
          " - " +
          end.cidade.cidade +
          "/" +
          end.cidade.uf;
      });

      return enderecoFormatado;
    },
    formatNomeFornecedor(fornecedor) {
      if (!fornecedor) return '-';

      var forne = JSON.parse(fornecedor);
      
      if (!forne) return;

      return forne.nome;
    },
    formatNomeVendedor(vendedor) {
      if (!vendedor) return '-';

      return vendedor.nome;
    },
    formaPordutoProducao(produto) {
      if (!produto) return '-';
      
      if (!produto) return;

      return produto.codigo + '- ' + produto.descricao;
    },
    statusMonitorLabel(status) {
      switch (status) {
        case 1:
          return "Não autorizado";
        case 2:
          return "Manifestada";
        case 3:
          return "Ciencia da emissão";
        case 4:
          return "Confirmação operação";
        case 5:
          return "Desconhecimento operação";
        case 6:
          return "Operação não realizada";
        case 7:
          return "Sem status";
        case 8:
          return "Autorizada";
        default:
          return "";
      }
    },
    statusMonitorClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-primary";
        case 2:
          return "label label-lg label-inline label-light-success";
        case 3:
          return "label label-lg label-inline label-light-danger";
        case 4:
          return "label label-lg label-inline label-light-success";
        case 5:
          return "label label-lg label-inline label-light-danger";
        case 6:
          return "label label-lg label-inline label-light-danger";
        case 7:
          return "label label-lg label-inline label-light-warning";
        case 8:
          return "label label-lg label-inline label-light-primary";
        default:
          return "label label-lg label-inline label-light-warning";
      }
    },
    statusPedidoClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-warning";
        case 3:
          return "label label-lg label-inline label-light-success";
        case 4:
          return "label label-lg label-inline label-light-danger";
        case 5:
          return "label label-lg label-inline label-light-secondary";
        case 8:
          return "label label-lg label-inline label-light-primary";
        case 9:
          return "label label-lg label-inline label-light-warning";
        case 10:
          return "label label-lg label-inline label-light-success";
        case 12:
            return "label label-lg label-inline label-light-success";
        default:
          return "label label-lg label-inline label-light-warning";
      }
    },
    statusOrdemCompraLabel(status) {
      switch (status) {
        case 1:
          return "Aberta";
        case 2:
          return "Em Andamento";
        case 3:
          return "Cancelado";
        case 4:
          return "Atendida";
        case 5:
          return "Encaminhada";
        default:
          return "";
      }
    },
    statusOrdemCompraClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-primary";
        case 2:
          return "label label-lg label-inline label-light-primary";
        case 3:
          return "label label-lg label-inline label-light-danger";
        case 4:
          return "label label-lg label-inline label-light-success";
        case 5:
          return "label label-lg label-inline label-light-warning";
        default:
          return "label label-lg label-inline label-light-warning";
      }
    },
    statusMdfeClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-warning";
        case 2:
          return "label label-lg label-inline label-light-success";
        case 3:
          return "label label-lg label-inline label-light-danger";
        case 4:
          return "label label-lg label-inline label-light-info";
        case 5:
          return "label label-lg label-inline label-light-primary";
        case 6:
          return "label label-lg label-inline label-light-danger";
        case 7:
          return "label label-lg label-inline label-light-danger";
        case 631:
          return "label label-lg label-inline label-light-danger";
        case 203:
          return "label label-lg label-inline label-light-danger";
        case null:
          return "";
        default:
          return "";
      }
    },
    statusClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-primary";
        case 2:
          return "label label-lg label-inline label-light-warning";
        case 3:
          return "label label-lg label-inline label-light-danger";
        case 4:
          return "label label-lg label-inline label-light-success";
        case 5:
          return "label label-lg label-inline label-light-danger";
        case 8:
          return "label label-lg label-inline label-light-danger";
        case 14:
          return "label label-lg label-inline label-light-warning";
        case null:
          return "label label-lg label-inline label-light-primary";
        default:
          return "label label-lg label-inline label-light-warning";
      }
    },
    statusProducaoClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-warning";
        case 2:
          return "label label-lg label-inline label-light-info";
        case 3:
          return "label label-lg label-inline label-light-danger";
        case 4:
          return "label label-lg label-inline label-light-warning";
        case 5:
          return "label label-lg label-inline label-light-success";
        case 6:
          return "label label-lg label-inline label-light-success";
        case null:
          return "Editando";
        default:
          return "";
      }
    },
    statusOSClass(status) {
      switch (status) {
        case 1:
          return "label label-lg label-inline label-light-info";
        case 2:
          return "label label-lg label-inline label-light-warning";
        case 3:
          return "label label-lg label-inline label-light-success";
        case 4:
          return "label label-lg label-inline label-light-danger";
        case 5:
          return "label label-lg label-inline label-light-success";
        case 6:
          return "label label-lg label-inline label-light-danger";
        case 7:
          return "label label-lg label-inline label-light-warning";
        case 8:
          return "label label-lg label-inline label-light-warning";
        case 9:
          return "label label-lg label-inline label-light-success";
        case 10:
          return "label label-lg label-inline label-light-warning";
        case 11:
          return "label label-lg label-inline label-light-success";
        case 12:
          return "label label-lg label-inline label-light-info";
        case 13:
            return "label label-lg label-inline label-light-success";
        case 14:
            return "label label-lg label-inline label-light-success";
        case 15:
            return "label label-lg label-inline label-light-warning";
        case null:
          return "Editando";
        default:
          return "";
      }
    },
    statusNFseLabel(status) {
      switch (status) {
        case 1:
          return "EDITANDO";
        case 2:
          return "PROCESSANDO";
        case 3:
          return "AUTORIZADA";
        case 4:
          return "CANCELADA";
        case 5:
          return "REJEITADA";
        default:
          return "";
      }
    },
    statusProducaoLabel(status) {
      switch (status) {
        case 1:
          return "Não iniciado";
        case 2:
          return "Em produção";
        case 3:
          return "Atrasado";
        case 4:
          return "Pausado";
        case 5:
          return "Entrega Parcial";
        case 6:
          return "Finalizado";
        default:
          return "";
      }
    },
    statusNFseClass(status) {
      switch (status) {
        case 2:
          return "label label-lg label-inline label-light-primary";
        case 1:
          return "label label-lg label-inline label-light-warning";
        case 3:
          return "label label-lg label-inline label-light-success";
        case 4:
          return "label label-lg label-inline label-light-danger";
        case 5:
          return "label label-lg label-inline label-light-danger";
        default:
          return "label label-lg label-inline label-light-warning";
      }
    },
    modeloPos(modelo) {
      switch (modelo) {
        case 1:
          return "Rede";
        case 2:
          return "Stone";
        case 3:
          return "Safra";
        case 4:
          return "Cielo";
        case 5:
          return "Fiserv";
        case 6:
          return "Vero";
        case 7:
          return "GetNet";
        case 8:
          return "Adiq";
        case 9:
          return "PagSeguro";
        case 10:
          return "Elgin";
        case 11:
          return "Punto";
        case 12:
          return "Caixa";
        case 13:
          return "Sicredi";
        default:
          return "";
      }
    },
    convertHexToRGBA(hexCode, opacity = 1) {
      let hex = hexCode.replace('#', '');

      if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
      }

      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      /* Backward compatibility for whole number based opacity values. */
      if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
      }
      return `rgba(${r},${g},${b},${opacity})`;
    },
    // vencimento > hj
    statusByDate(dataItem) {
      if (dataItem.unidade_medida !== undefined) {
        return dataItem.ativo ? 'Ativo' : 'Inativo'
      }
      if (dataItem.data_recebimento !== undefined) {
        if (
          dataItem.data_vencimento < this.thisDate() &&
          dataItem.data_recebimento === null
        ) {
          return 'Vencida';
        }
        if (
          dataItem.data_vencimento > this.thisDate() &&
          dataItem.data_recebimento === null
        ) {
          return 'A receber';
        }
        if (dataItem.data_recebimento !== null) {
          return 'Recebida';
        }
      } else {
        if (
          dataItem.data_vencimento < this.thisDate() &&
          dataItem.data_pagamento === null
        ) {
          return 'Atrasada';
        }
        if (
          dataItem.data_vencimento > this.thisDate() &&
          dataItem.data_pagamento === null
        ) {
          return 'Pendente';
        }
        if (dataItem.pago == true) {
          return 'Paga';
        }
      }
      return "";
    },
    async getFinalidadeSintegra() {
      const { data } = await this.$http.post("/relatorios/finalidade-sintegra");
      return data;
    },
    async getRegistrosSintegra() {
      const { data } = await this.$http.post("/relatorios/registros-sintegra");
      return data;
    },
    async getTipoInventarioSintegra() {
      const { data } = await this.$http.post("/relatorios/tipo-inventario-sintegra");
      return data;
    },
    async getBancoZoopByCode(code) {
      const { data } = await this.$http.post("/bancos/lista", {
        code,
      });
      return data;
    },
    async getMenus() {
      const { data } = await this.$http.post("/sistema/menus");
      return data;
    },
    async getCSTCofinsByCodigo(codigo) {
      const { data } = await this.$http.post("/sistema/cst-cofins-by-codigo", {
        codigo,
      });
      return data;
    },
    async getConsultaReceita(cnpj) {
      const { data } = await this.$http.post("/pessoa/receita-dados", {
        cnpj_cpf: cnpj,
      });
      return data;
    },
    async getConsultaCPF(cpf) {
      const { data } = await this.$http.post("/pessoa/consulta-nuts", {
        cpf: cpf,
      });

      return data;
    },
    async getAniversariantes() {
      const { data } = await this.$http.post("/pessoa/aniversariantes", {
        dia: moment().format('YYYY-MM-DD'),
      });

      return data;
    },
    numberToReal(valor, casas) {
      var positivo = true;
      var sifrao = false;
      const casasDecimais = typeof casas === 'number' ? casas : casas === 'quantidade' ? quantityOptions.precision : priceOptions.precision
      if (valor) {
        if (valor < 0) {
          positivo = false;
          valor = valor * -1;
        }

        var numero = parseFloat(valor);
        numero = numero.toFixed(casasDecimais).split(".");

        if (sifrao) {
          numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join(".");
        } else {
          numero[0] = numero[0].split(/(?=(?:...)*$)/).join(".");
        }

        if (positivo == false) {
          return "-" + numero.join(",");
        }

        return numero.join(",");
      }
      return casasDecimais === 2 ?
        "0,00" :
        casasDecimais === 3 ? '0,000' :
          '0,0000';
    },
    formatDate(value, fmt = "DD/MM/YYYY ") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
    thisDateDashboard() {
      return moment().format("DD/MM/YYYY");
    },
    thisDate() {
      return moment().format("YYYY-MM-DD");
    },
    thisTime() {
      return moment().format("HH:mm:ss");
    },
    addLoadingToButton(ref) {
      const submitButton = this.$refs[ref]

      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.setAttribute("disabled", "");
    },
    formatNumberToFloat(numberString) {
      if (typeof numberString === 'number') return numberString;

      const partes = numberString.split(/[,.]/);
      let decimalExtension = 0
      if (partes.length > 1) {
        const casasDecimais = partes[partes.length - 1];
        decimalExtension = casasDecimais.length;
      }

      const valorNumerico = parseFloat(numberString.replaceAll(',', '').replaceAll('.', ''));

      const valueFormatted = valorNumerico / (10 ** decimalExtension);

      return valueFormatted;
    },
    getCurrentWeekData(moduleName) {
      const currentDate = moment();

      const weekStart = currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD');
      const weekEnd = currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data_vencimento: [weekStart, weekEnd]
      }
    },

    getCurrentWeekDataFinanceiro(moduleName) {
      const currentDate = moment();

      const weekStart = currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD');
      const weekEnd = currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data: [weekStart, weekEnd]
      }
    },

    getCurrentDayData(moduleName) {
      const currentDate = moment().format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data_vencimento: [currentDate]
      }
    },

    getCurrentDayDataFinanceiro(moduleName) {
      const currentDate = moment().format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data: [currentDate]
      }
    },

    getYesterdayDataFinanceiro(moduleName) {
      const currentDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data: [currentDate]
      }
    },

    getYesterdayData(moduleName) {
      const currentDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data_vencimento: [currentDate]
      }
    },
    getMonthData(moduleName) {
      const currentDate = moment();
      const monthStart = currentDate.clone().startOf('month').format('YYYY-MM-DD');
      const monthEnd = currentDate.clone().endOf('month').format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data_vencimento: [monthStart, monthEnd]
      }
    },
    getMonthDataFinanceiro(moduleName) {
      const currentDate = moment();
      const monthStart = currentDate.clone().startOf('month').format('YYYY-MM-DD');
      const monthEnd = currentDate.clone().endOf('month').format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data: [monthStart, monthEnd]
      }
    },
    getLastWeekData(moduleName) {
      const currentDate = moment();
      const weekStart = currentDate.clone().subtract('days', 7).startOf('isoWeek').format('YYYY-MM-DD');
      const weekEnd = currentDate.clone().subtract('days', 7).endOf('isoWeek').format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data_vencimento: [weekStart, weekEnd]
      }
    },
    getLastWeekDataFiananceiro(moduleName) {
      const currentDate = moment();
      const weekStart = currentDate.clone().subtract('days', 7).startOf('isoWeek').format('YYYY-MM-DD');
      const weekEnd = currentDate.clone().subtract('days', 7).endOf('isoWeek').format('YYYY-MM-DD');

      filtersStore[moduleName].filters = {
        ...filtersStore[moduleName].filters,
        range_data: [weekStart, weekEnd]
      }
    },
    removeLoadingToButton(ref) {
      const submitButton = this.$refs[ref]

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.removeAttribute("disabled", "");
    },
    thisHours() {
      return moment().format("HH:mm:ss");
    },
    formatCpfCnpj(valor) {
      if (valor === null) {
        return "";
      }
      if (valor.length <= 11) {
        // eslint-disable-next-line
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, `\$1.\$2.\$3\-\$4`);
      }
      // eslint-disable-next-line
      return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    },
    defineParcelas(parcelas, valor, vencimento) {
      const pagamento = [];
      for (let i = 0; i < parcelas; i++) {
        let vencimentoDate = new Date(vencimento);
        vencimentoDate.setMonth(vencimentoDate.getMonth() + i + 1)
        vencimentoDate.setDate(vencimentoDate.getDate() + 1)
        pagamento.push({
          valor,
          data_vencimento: moment(vencimentoDate).format('YYYY-MM-DD')
        })
      }
      return pagamento
    },
    formatNumberToFloat(numberString) {
      if (typeof numberString === 'number') return numberString;

      const partes = numberString.split(/[,.]/);
      let decimalExtension = 0
      if (partes.length > 1) {
        const casasDecimais = partes[partes.length - 1];
        decimalExtension = casasDecimais.length;
      }

      const valorNumerico = parseFloat(numberString.replaceAll(',', '').replaceAll('.', ''));

      const valueFormatted = valorNumerico / (10 ** decimalExtension);

      return valueFormatted;
    },
    formatDataToSendToApi(
      formNatureza,
      formProduto,
      formCliente,
      formFaturamento,
      formImpostos,
      formIntermediador,
      formTransportador,
      formRural,
    ) {
      const data = {
        modelo: 55,
        generationDate: formNatureza.dataEmissao,
        freightType: formTransportador ? formTransportador.frete : {
          codigo: 9,
          descricao: 'Sem Ocorrrência de Transporte'
        },
        ...(store.orcamentos.length > 0 && {
          orcamentos: store.orcamentos,
        }),
        ...(store.pedidos.length > 0 && {
          pedidos: store.pedidos,
        }),
        ...(store.os.length > 0 && {
          os: store.os,
        }),
        ...formImpostos,
        freightPrice: formImpostos.freightPrice,
        insurance: formImpostos.insurance,
        extraValue: formImpostos.extraValue,
        price: 0,
        discountIsPercentage: false,
        tpNF: 1, // manter 1
        keyDevolution: formNatureza?.keyDevolution ?? '',
        exporta: { // Manter vazio
        },
        ...(!!formIntermediador && {
          tipoIntermediador: formIntermediador.id,
          intermediador: { ...formIntermediador },
          usuarioIntermediador: formIntermediador.indicador.id === 0 ? '' : formIntermediador.usuario,
        }),
        defaultValues: true,   // deixar como true sempre
        remainingPrice: 0, // Deixar zerado
        // discount: 0, ///desconto total nota no impostoData
        natureza: formNatureza.natureza,
        ...(!!formNatureza.vendedor && {
          vendedor: {
            id_vendedor: formNatureza.vendedor.id_vendedor,
            nome: formNatureza.vendedor.nome,
          },
        }),
        serie: formNatureza.serie,
        ...(!!formCliente.cnpj_cpf && {
          buyer: {
            id_pessoa: formCliente.id_pessoa,
            cnpj_cpf: formCliente.cnpj_cpf,
            rg: formCliente.ie_rg,
            suframaCode: formCliente.codigo,
            nome: formCliente.nome,
            indicadorIE: formCliente.identificador_ie ?? 9,
            produtor_rural: formCliente.produtor_rural,
            estrangeiro: formCliente.estrangeiro,
          },
          shippingInfo: {
            address: {
              id_pessoa_endereco: formCliente.endereco.id_pessoa_endereco,
              cep: formCliente.endereco.cep,
              street: formCliente.endereco.logradouro,
              number: formCliente.endereco.numero,
              complement: formCliente.endereco.complemento,
              neighbourhood: formCliente.endereco.bairro,
              id_pessoa: formCliente.endereco.id_pessoa,
              id_cidade: formCliente.endereco.id_cidade,
              id_tipo: formCliente.endereco.id_tipo,
            },
            fone: formCliente.fone,
            email: formCliente.email,
            contactPerson: formCliente.contato,
          },
        }),

        rural: formRural ?? {},
        productOfTradeCollection: formProduto.produtos,

        ...(!!formTransportador?.cnpj_cpf && {
          deliveryInfo: {
            delivery: {
              ...formTransportador,
              name: formTransportador?.nome ?? null,
            },
            address: {
              id_pessoa_endereco: formTransportador?.enderecos[0]?.id_pessoa_endereco ?? null,
              cep: formTransportador?.enderecos[0]?.cep ?? null,
              street: formTransportador?.enderecos[0]?.logradouro ?? null,
              number: formTransportador?.enderecos[0]?.numero ?? null,
              complement: formTransportador?.enderecos[0]?.complement ?? null,
              neighbourhood: formTransportador?.enderecos[0]?.bairro ?? null,
              id_pessoa: formTransportador?.enderecos[0]?.id_pessoa ?? null,
              id_cidade: formTransportador?.enderecos[0]?.id_cidade ?? null,
              id_tipo: formTransportador?.enderecos[0]?.id_tipo ?? null,
            },
            veiculo: {
              ...(formTransportador?.veiculo && { ...formTransportador?.veiculo })
            },
          }
        }),
        ...(!!formTransportador && {
          volume: {
            quantity: formTransportador?.quantidade ?? null,
            species: formTransportador?.especie ?? null,
            brand: formTransportador?.marca ?? null,
            numeration: formTransportador?.numeracao ?? null,
            grossWeight: formTransportador?.pesoBruto ?? null,
            netWeight: formTransportador?.pesoLiquido ?? null,
          },
        }),
        ...(!!formFaturamento && {
          observation: formFaturamento.informacoes_adicionais,
          id_plano_contas: formFaturamento.pagamentos[0]?.plano_conta?.id ?? 2,
          paymentModeCollection: formFaturamento.pagamentos.map(pagamento => {
            let vezes = 1;
            if (pagamento.forma_pagamento.descricao.includes('A prazo')
              || pagamento.forma_pagamento.descricao.includes('Cartão de crédito')
              || pagamento.forma_pagamento.descricao.includes('Cartão de débito')) {
              vezes = this.getParcelas(pagamento.forma_pagamento.descricao);
            }
            return pagamento.forma_pagamento.descricao !== 'Nenhum' ? ({
              id: pagamento.forma_pagamento.id,
              descricao: pagamento.forma_pagamento.descricao,
              id_meio_pagamento: pagamento.forma_pagamento.id_meio_pagamento,
              vezes,
              requer_entrada: pagamento.forma_pagamento.requer_entrada,
              deleted_at: null,
              created_at: pagamento.forma_pagamento.createdAt,
              updated_at: pagamento.forma_pagamento.updatedAt,
              value: pagamento.valor,
              paymentMode: {
                description: pagamento.forma_pagamento.descricao,
                id: pagamento.forma_pagamento.id_meio_pagamento,
                name: pagamento.forma_pagamento.descricao
              },
              range: pagamento.range
            })
              :
              ({
                id: pagamento.forma_pagamento.id,
                descricao: pagamento.forma_pagamento.descricao,
                id_meio_pagamento: pagamento.forma_pagamento.id_meio_pagamento,
                vezes,
                requer_entrada: pagamento.forma_pagamento.requer_entrada,
                deleted_at: null,
                created_at: pagamento.forma_pagamento.createdAt,
                updated_at: pagamento.forma_pagamento.updatedAt,
                value: 0,
                paymentMode: {
                  description: pagamento.forma_pagamento.descricao,
                  id: pagamento.forma_pagamento.id_meio_pagamento,
                  name: pagamento.forma_pagamento.descricao
                },
                range: [{
                  value: 0,
                  date: this.thisDate()
                }],
              })
          })
        }),

        finNFe: formNatureza.finalidade.codigo ?? formNatureza.finalidade.id,  // codigo da finalidade da natureza

        outputDateTime: formNatureza.dataSaida + ' ' + formNatureza.horaSaida, // data de saida (natureza)
      }

      return data
    },
    extractOSByNFSeDataFromImport(data) {
      const formTomador = data?.cliente?.cnpj_cpf ? {
        ...data.cliente,
        endereco: data.endereco,
      } : null;

      const formServicos = data.servicos ? [
        ...data.servicos,
      ] : null;

      const formPagamento = null;

      const informacoesComplementares = data?.informacoes_complementares;

      return [formServicos, formTomador, formPagamento, informacoesComplementares];
    },
    extractDataFromImport(data) {
      const formFaturamento = data.paymentModeCollection ? {
        pagamentos: [{
          forma_pagamento: {
            id: data.paymentModeCollection[0].id,
            descricao: data.paymentModeCollection[0].descricao,
            id_meio_pagamento: data.paymentModeCollection[0].id_meio_pagamento,
            vezes: 1,
            requer_entrada: data.paymentModeCollection[0].requer_entrada,
            value: 0,
          },
          informacoes_adicionais: data.observation,
          range: data.paymentModeCollection[0].range,
          valor: 0,
          vencimento: data.paymentModeCollection[0].range[0].date
        }]
      } : null
      const formCliente = data?.buyer?.cnpj_cpf ? {
        ...data.buyer,
        fone: data.buyer?.fones[0],
        email: data.buyer?.emails[0],
        endereco: data.buyer?.enderecos[0],
        contactPerson: data.shippingInfo?.contactPerson
      } : null;

      const formProduto = {
        produtos: data.productOfTradeCollection.map(produto => ({ ...produto })),
        subtotal: data.productOfTradeCollection.reduce((acc, product) => acc + (product.price * product.quantity - product.discount), 0)
      };

      formProduto.produtos.forEach((produto, index) => {
        formProduto.produtos[index].currentIndex = index;
      });
      const formTransportador = {
        deliveryInfo: data?.deliveryInfo,
        volume: data?.volume,
        freightType: data?.freightType,
      }

      return [formFaturamento, formProduto, formCliente, formTransportador];
    },
    formatDataToSendToOrcamentoApi(cliente, formProdutos, pagamentos, informacoes, transportador) {
      const data = {
        status: informacoes.status.id,
        cliente: {
          id_pessoa: cliente.id_pessoa,
        },
        pedido_compra: informacoes.pedido_compra ?? null,
        solicitante: cliente.solicitante ?? null,
        vendedor: cliente.vendedor ?? null,
        dataValidade: informacoes.validade,
        observacao: informacoes.observacao,
        produtos: formProdutos.produtos,
        pagamentos: pagamentos.pagamentos.map(pagamento => {
          let vezes = 1;
          if (pagamento.forma_pagamento.descricao.includes('A prazo')
            || pagamento.forma_pagamento.descricao.includes('Cartão de crédito')
            || pagamento.forma_pagamento.descricao.includes('Cartão de débito')) {
            vezes = this.getParcelas(pagamento.forma_pagamento.descricao);
          }
          return ({
            id: pagamento.forma_pagamento.id,
            descricao: pagamento.forma_pagamento.descricao,
            id_meio_pagamento: pagamento.forma_pagamento.id_meio_pagamento,
            vezes,
            requer_entrada: pagamento.forma_pagamento.requer_entrada,
            deleted_at: null,
            created_at: pagamento.forma_pagamento.createdAt,
            updated_at: pagamento.forma_pagamento.updatedAt,
            value: pagamento.valor,
            paymentMode: {
              description: pagamento.forma_pagamento.descricao,
              id: pagamento.forma_pagamento.id_meio_pagamento,
              name: pagamento.forma_pagamento.descricao,
            },
            range: pagamento.range,
          })
        }),
        valorTotal: formProdutos.subtotal - pagamentos.desconto,
        descontoPorcentage: false,
        desconto: pagamentos.desconto,
        ...(!!transportador?.nome && {
          transportador: {
            deliveryInfo: {
              delivery: {
                ...transportador,
                name: transportador?.nome ?? null,
              },
              address: {
                id_pessoa_endereco: transportador?.enderecos[0]?.id_pessoa_endereco ?? null,
                cep: transportador?.enderecos[0]?.cep ?? null,
                street: transportador?.enderecos[0]?.logradouro ?? null,
                number: transportador?.enderecos[0]?.numero ?? null,
                complement: transportador?.enderecos[0]?.complement ?? null,
                neighbourhood: transportador?.enderecos[0]?.bairro ?? null,
                id_pessoa: transportador?.enderecos[0]?.id_pessoa ?? null,
                id_cidade: transportador?.enderecos[0]?.id_cidade ?? null,
                id_tipo: transportador?.enderecos[0]?.id_tipo ?? null,
              },
              veiculo: {
                ...(transportador?.veiculo && { ...transportador?.veiculo })
              },
            },
            volume: {
              quantity: transportador?.quantidade ?? null,
              species: transportador?.especie ?? null,
              brand: transportador?.marca ?? null,
              numeration: transportador?.numeracao ?? null,
              grossWeight: transportador?.pesoBruto ?? null,
              netWeight: transportador?.pesoLiquido ?? null,
            },
          }
        }),
      };

      return data;
    },

    formatDataToSendToOrdemApi(fornecedor, formProdutos, pagamentos, informacoes) {
      const data = {
        status: informacoes.status.id,
        fornecedor: {
          id_pessoa: fornecedor.id_pessoa,
        },
        data_solicitacao: informacoes.data_solicitacao,
        observacao: informacoes.observacao,
        produtos: formProdutos.produtos,
        pagamentos: pagamentos.pagamentos.map(pagamento => {
          let vezes = 1;
          if (pagamento.forma_pagamento.descricao.includes('A prazo')
            || pagamento.forma_pagamento.descricao.includes('Cartão de crédito')
            || pagamento.forma_pagamento.descricao.includes('Cartão de débito')) {
            vezes = this.getParcelas(pagamento.forma_pagamento.descricao);
          }
          return ({
            id: pagamento.forma_pagamento.id,
            descricao: pagamento.forma_pagamento.descricao,
            id_meio_pagamento: pagamento.forma_pagamento.id_meio_pagamento,
            vezes,
            requer_entrada: pagamento.forma_pagamento.requer_entrada,
            created_at: pagamento.forma_pagamento.createdAt,
            updated_at: pagamento.forma_pagamento.updatedAt,
            value: pagamento.valor,
            paymentMode: {
              description: pagamento.forma_pagamento.descricao,
              id: pagamento.forma_pagamento.id_meio_pagamento,
              name: pagamento.forma_pagamento.descricao,
            },
            range: pagamento.range,
          })
        }),
        valorTotal: formProdutos.subtotal,
      };

      return data;
    },

    formatDataToSendToOSApi() {
      let data = new FormData();
      const totalServicos = JSON.stringify(OSStore.formServicos) !== '{}' && OSStore.formServicos !== null
        ? OSStore.formServicos.reduce((acc, servico) => acc + servico.total, 0) : 0;
      const totalProdutos = OSStore.formProduto?.subtotal ?? 0
      if (!!OSStore.formAnexos.anexo_1 && typeof OSStore.formAnexos.anexo_1 !== 'string') {
        data.append("anexo_1", OSStore.formAnexos.anexo_1);
      }
      if (!!OSStore.formAnexos.anexo_2 && typeof OSStore.formAnexos.anexo_2 !== 'string') {
        data.append("anexo_2", OSStore.formAnexos.anexo_2);
      }
      if (!!OSStore.formAnexos.anexo_3 && typeof OSStore.formAnexos.anexo_3 !== 'string') {
        data.append("anexo_3", OSStore.formAnexos.anexo_3);
      }
      if (!!OSStore.formAnexos.anexo_4 && typeof OSStore.formAnexos.anexo_4 !== 'string') {
        data.append("anexo_4", OSStore.formAnexos.anexo_4);
      }
      if (!!OSStore.formAnexos.anexo_5 && typeof OSStore.formAnexos.anexo_5 !== 'string') {
        data.append("anexo_5", OSStore.formAnexos.anexo_5);
      }
      if (!!OSStore.formAnexos.anexo_6 && typeof OSStore.formAnexos.anexo_6 !== 'string') {
        data.append("anexo_6", OSStore.formAnexos.anexo_6);
      }
      if (!!OSStore.formGerais.cliente.id_pessoa) {
        data.append("id_pessoa", OSStore.formGerais.cliente.id_pessoa);
      }
      if (!!OSStore.formGerais.cliente.endereco) {
        data.append("endereco", JSON.stringify(OSStore.formGerais.cliente.endereco));
      }
      if (!!OSStore.formGerais.tecnico) {
        if (!!OSStore.formGerais.tecnico.id_tecnico) {
          data.append("id_tecnico", OSStore.formGerais.tecnico.id_tecnico);
        }
      }
      if (!!OSStore.formObjeto.objeto.id_objeto) {
        data.append("id_objeto", OSStore.formObjeto.objeto.id_objeto);
      }
      if (!!OSStore.formGerais.dataEntrada) {
        data.append("dataEntrada", OSStore.formGerais.dataEntrada);
      }
      if (!!OSStore.formGerais.dataPrevisao) {
        data.append("dataPrevisao", OSStore.formGerais.dataPrevisao);
      }
      if (!!OSStore.formGerais) {
        if (OSStore.formGerais.garantia) {
          data.append("garantia", OSStore.formGerais.garantia);
        }
        if (!OSStore.formGerais.garantia) {
          data.append("garantia", '');
        }
      }
      if (!!OSStore.formProblema) {
        if (OSStore.formProblema.problema) {
          data.append("problema", OSStore.formProblema.problema);
        }
        if (!OSStore.formProblema.problema) {
          data.append("problema", '');
        }
      }
      if (!!OSStore.formObservacoes) {
        if (OSStore.formObservacoes.observacaoInterna) {
          data.append("observacaoInterna", OSStore.formObservacoes.observacaoInterna);
        }
        if (!OSStore.formObservacoes.observacaoInterna) {
          data.append("observacaoInterna", '');
        }
      }
      if (!!OSStore.formObservacoes) {
        if (OSStore.formObservacoes.observacao) {
          data.append("observacao", OSStore.formObservacoes.observacao);
        }
        if (!OSStore.formObservacoes.observacao) {
          data.append("observacao", '');
        }
      }
      if (!!OSStore.formGerais.dataSaida) {
        data.append("dataSaida", OSStore.formGerais.dataSaida);
      }
      if (!!OSStore.formGerais.proxRevisao) {
        data.append("proximaRevisao", OSStore.formGerais.proxRevisao);
      }
      data.append("valorTotal", totalProdutos + totalServicos - OSStore.formPagamentos.desconto);
      if (OSStore.formProduto?.produtos?.length !== undefined && OSStore.formProduto?.produtos.length !== 0) {
        data.append("produtos", JSON.stringify(OSStore.formProduto.produtos));
      }
      if (JSON.stringify(OSStore.formServicos) !== '{}') {
        data.append("servicos", JSON.stringify(OSStore.formServicos));
      }
      data.append("identificadores", JSON.stringify(OSStore.formObjeto.identificadores));
      data.append("pagamentos", JSON.stringify(OSStore.formPagamentos.pagamentos));
      data.append('desconto', OSStore.formPagamentos.desconto);

      return data;
    },

  },
  toCamelCase(string) {
    return string.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }
};